import React from 'react';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import ScrollToTop from 'components/widgets/Scroll_To_Top.js';
import CompletedOffer from 'components/offers/Completed_Offer.js';
import Platform from 'utils/Platform.js';
import Formatting from 'utils/Formatting.js';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;

let thisComponent;
let markedDifficultyId = 'all-offers';

const difficultyClasses = { '1': 'green', '2': 'yellow', '3': 'red'};

class CompletedOffersList extends React.Component {
    constructor(props) {
        super(props);
        this.scrollDone = false;
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
        thisComponent = this;
    }

    reloadComponent() {

        thisComponent.setState({
            error: null,
            isLoaded: false,
            items: []
        });

        let platform = Platform.getPlatformName();

        const req = ApiFacade.completedOffers(platform);
        req.then(function (res) {
                if (res.ok){
                    let result = res.response;
                    UserData.setItem(userDataKeys.offer_completion_disclaimer_displayed, result.coinsDisclaimerDisplayed);
                    thisComponent.setState({
                        isLoaded: true,
                        items: result.apps
                    });
                } else {
                    thisComponent.setState({
                        isLoaded: true,
                        error: res.error
                    });
                }
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    componentDidMount() {
        thisComponent.reloadComponent(1, 3);
        
    }

    displayDetails(offer) {
        thisComponent.displayOfferDetails(offer.imageURL, offer.detailsImageURL, offer.description, offer.detailedDescription, offer.hashTags, offer.difficultyLevel, offer.difficultyLevelID, offer.appName, offer.coins, (ApiFacade.isLoggedIn()), offer.url, offer.isUnlimited);
    }

    generateOffersList(appName, offers, hashAppName) {
        const hash = window.location.hash.substring(1);

        return (
            <div id={`app-offers-${appName}-${offers[0].uniqueID}`} className='app-list-offers open'>
                {
                    offers.map(offer => (
                        <CompletedOffer 
                            isMultiEvent={Boolean(offer.totalSteps)}
                            key={`_offer-completed-${offer.ID}_${Date.now()}`} 
                            brandsExpanded={hash===hashAppName}
                            offer={offer} 
                        />
                    ))
                }
            </div>
        );
    }

    displayOfferDetails(imageURL, detailsImageURL, offerDescription, detailedDescription, hashTags, difficultyLevel, difficultyLevelID, appName, coins, loggedIn, url, isUnlimited) {
        document.getElementById('offer-details-div-overlay').className = 'offer-details-div-overlay';
        document.getElementById('offer-details-div').className = 'offer-details-div';
        document.getElementById('offer-data-image').src = imageURL;
        document.getElementById('offer-data-difficulty-image').src = Images.imageURL('difficulties/' + difficultyLevel.toLowerCase() + '-white.png');
        document.getElementById('offer-data-difficulty-popup-text').innerHTML = Content.getValue("offer_difficulties")[difficultyLevelID.toString()];
        document.getElementById('offer-data-image-details').src = detailsImageURL;
        document.getElementById('offer-data-app-name').innerHTML = appName;
        document.getElementById('offer-data-description').innerHTML = offerDescription;
        document.getElementById('offer-data-detailed-description').innerHTML = detailedDescription.replaceAll('---', '<br><br>');
        document.getElementById('offer-data-hash-tags').innerHTML = hashTags;
        document.getElementById('offer-data-difficulty-mark').className = 'list-offer-container-difficulty-mark ' + difficultyClasses[difficultyLevelID.toString()];
        document.getElementById('offer-data-difficulty-level').className = 'offer-difficulty-label ' + difficultyClasses[difficultyLevelID.toString()];
        document.getElementById('offer-data-difficulty-level').innerHTML = '<div class="difficulty-fragment">' + Content.getValue("offer_difficulties")[difficultyLevelID.toString()] + '</div><div class="difficulty-fragment black-text">' + Content.getValue("offer_difficulties")[difficultyLevel.toLowerCase()] + '</div><div class="difficulty-fragment black-text">' + Content.getValue("level_label") + '</div>';
        document.getElementById('offer-data-button').innerHTML = Formatting.format(Content.getValue("earn_amount_treat_coins"), ['(coins)'], [coins]);
        if (isUnlimited) {
            document.getElementById('offer-data-unlimited-sign').style.display = 'flex';
            document.getElementById('offer-data-unlimited').style.display = 'flex';
            document.getElementById('offer-data-unlimited').innerHTML = Content.getValue("complete_offers_unlimited_times");
        }
        if (loggedIn) document.getElementById('offer-details-url').value = url;
    }

    setDifficultyLevel(idToMark) {
        document.getElementById(markedDifficultyId).className = document.getElementById(markedDifficultyId).className.replace(" marked", "");
        document.getElementById(idToMark).className += " marked";
        markedDifficultyId = idToMark;
        thisComponent.reloadComponent();
    }

    render() {
        const { error, isLoaded, items } = this.state;

        if (error) {
            return <>
                <div className="offers-list-difficulty">
                    <div className="offers-section-difficulty all-offers marked" onClick={() => thisComponent.setDifficultyLevel('all-offers')} id="all-offers">{Content.getValue("offer_difficulties")["0"]}</div>
                    <div className="offers-section-difficulty easy-offers" onClick={() => thisComponent.setDifficultyLevel('easy-offers')} id="easy-offers">{Content.getValue("offer_difficulties")["1"]}</div>
                    <div className="offers-section-difficulty medium-offers" onClick={() => thisComponent.setDifficultyLevel('medium-offers')} id="medium-offers">{Content.getValue("offer_difficulties")["2"]}</div>
                    <div className="offers-section-difficulty hard-offers" onClick={() => thisComponent.setDifficultyLevel('hard-offers')} id="hard-offers">{Content.getValue("offer_difficulties")["3"]}</div>
                </div>
                <div className="error-message">{Backend.loadBackendMessages().errorMessage}</div>
            </>;
        } else if (!isLoaded) {
            return <>
                <div className="offers-list-difficulty">
                    <div className="offers-section-difficulty all-offers marked" onClick={() => thisComponent.setDifficultyLevel('all-offers')} id="all-offers">{Content.getValue("offer_difficulties")["0"]}</div>
                    <div className="offers-section-difficulty easy-offers" onClick={() => thisComponent.setDifficultyLevel('easy-offers')} id="easy-offers">{Content.getValue("offer_difficulties")["1"]}</div>
                    <div className="offers-section-difficulty medium-offers" onClick={() => thisComponent.setDifficultyLevel('medium-offers')} id="medium-offers">{Content.getValue("offer_difficulties")["2"]}</div>
                    <div className="offers-section-difficulty hard-offers" onClick={() => thisComponent.setDifficultyLevel('hard-offers')} id="hard-offers">{Content.getValue("offer_difficulties")["3"]}</div>
                </div>
                <div className="loading-message">{Backend.loadBackendMessages().loadingMessage}</div>
                </>;
        } else {
            setTimeout(function () {
                if (window.location.hash !== '' && thisComponent.scrollDone === false) {
                    let appSection = window.location.hash.substring(1) + "-section";
                    let element = document.getElementById(appSection);
                    if (element) {
                        const y = element.getBoundingClientRect().top + window.pageYOffset - 68;
                        window.scrollTo({top: y, behavior: 'smooth'});
                    }

                    thisComponent.scrollDone = true;
                }
            }, 100);
            return (
                <>
                    <div className="offers-list-difficulty">
                        <div className="offers-section-difficulty all-offers marked" onClick={() => thisComponent.setDifficultyLevel('all-offers')} id="all-offers">{Content.getValue("offer_difficulties")["0"]}</div>
                        <div className="offers-section-difficulty easy-offers" onClick={() => thisComponent.setDifficultyLevel('easy-offers')} id="easy-offers">{Content.getValue("offer_difficulties")["1"]}</div>
                        <div className="offers-section-difficulty medium-offers" onClick={() => thisComponent.setDifficultyLevel('medium-offers')} id="medium-offers">{Content.getValue("offer_difficulties")["2"]}</div>
                        <div className="offers-section-difficulty hard-offers" onClick={() => thisComponent.setDifficultyLevel('hard-offers')} id="hard-offers">{Content.getValue("offer_difficulties")["3"]}</div>
                    </div>
                    <div className={(items?.length > 0) ? 'desktop list-offers-div-wrapper' : ''}>
                        <div className={(items?.length > 0) ? 'desktop list-offers-div  filled' : 'desktop list-offers-div '}>
                        {
                        (items?.length > 0) ? (                    
                        items?.map(
                            function(item) {
                                const appName = item.appName.replaceAll(" ", "_").toLowerCase();
                                    return (
                                        <React.Fragment key={`app-${appName}`}>
                                            <div key={`app-${appName}-${Math.random()}`} className="app-wrapper" id={`${appName}-section`}>
                                                {thisComponent.generateOffersList(item.appName, item.offers, appName)}
                                            </div>
                                        </React.Fragment>
                                    );
                            }
                        )
                        ) : (
                            <div className="no-completed-offers">{Content.getValue("no_offers_completed")}</div>
                        )
                        }
                            </div>
                    </div>
                    {(items?.length > 0) ? (
                        <>
                            <ScrollToTop containerClass="scroll-to-top-container mobile offers-list" />
                            <div className="scroll-to-top-container desktop completed-offers">
                                <ScrollToTop containerClass="scroll-to-top-container offers-list" />
                            </div>
                        </>
                    ) : 
                        (null)
                    }
                </>
            );
        }
    }

}

export default CompletedOffersList;