import OfferEvents from 'utils/Offer_Events.js';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import Platform from 'utils/Platform.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Content from 'utils/Content.js';
import FeaturedOffer from 'components/offers/Featured_Offer.js';
import BlurProvider from 'components/offer_providers/Blur_Provider.js';

import { OfferPopupContext } from 'context/Offer_Popup_Context.js';
import Alerts from './Alerts';

const configuration = require('configuration.json');

const offerSortOptions = configuration.offer_sort_options;
const offerDifficulties = configuration.offer_difficulties;
const offerDifficultiesToValues = configuration.offer_difficulties_to_values;

const MySwal = withReactContent(Swal);

class Offers {
    constructor() {
        this.context = OfferPopupContext;
    }

    static hideOfferDetails() {
        if (document.getElementById('offer-details-div-overlay') === null) {
            return;
        }
        const useCompetitionContainer = document.getElementById('offer-details-use-competition-container');
        const completionDisclaimerContainer = document.getElementById('offer-completion-disclaimer-container');

        OfferEvents.recordOfferPageVisit();
        document.getElementById("offer-details-div-overlay").className = "offer-details-div-overlay hidden";
        document.getElementById("offer-details-div").className = "offer-details-div hidden";
        document.getElementById("offer-data-image-details").src = "";
        document.getElementById("offer-data-difficulty-image").src = "";
        document.getElementById("offer-data-difficulty-popup-text").innerHTML = "";
        document.getElementById("offer-data-image").src = "";
        document.getElementById("offer-data-app-name").innerHTML = "";
        document.getElementById("offer-data-description").innerHTML = "";
        document.getElementById("offer-data-detailed-description").innerHTML = "";
        document.getElementById("offer-details-users-completed").innerHTML = "";
        document.getElementById("offer-data-hash-tags").innerHTML = "";
        document.getElementById("offer-data-difficulty-mark").className = "list-offer-container-difficulty-mark";
        document.getElementById("offer-data-difficulty-level").className = "offer-difficulty-label";
        document.getElementById("offer-data-difficulty-level").innerHTML = "";
        if (useCompetitionContainer) useCompetitionContainer.style.display = 'none';
        if (completionDisclaimerContainer) completionDisclaimerContainer.className = 'offer-completion-disclaimer-container hidden';

        document.getElementById("offer-data-unlimited").style.display = "none";
        document.getElementById("offer-data-unlimited-sign").style.display = "none";
        document.getElementById("offer-data-unlimited").innerHTML = "";

        document.getElementById("offer-data-percentage").style.display = "none";
        document.getElementById("offer-data-percentage-sign").style.display = "none";
        document.getElementById("offer-data-percentage").innerHTML = "";

        document.getElementById("offer-data-daily").style.display = "none";
        document.getElementById("offer-data-daily-sign").style.display = "none";
        document.getElementById("offer-data-daily-amount").innerHTML = "";
        document.getElementById("offer-data-daily").innerHTML = "";

        document.getElementById("offer-data-button").innerHTML = "";
        document.getElementById("offer-details-url").value = "";
        document.getElementById("offer-details-id").value = "";
        document.getElementById("offer-details-type").value = "";
        document.getElementById("offer-details-referral-container").className = "offer-referral-content";
        document.getElementById("offer-details-system-container").className = "offer-referral-content";
        document.getElementById("offer-details-popup-id").innerHTML = "";
        document.getElementById("offer-details-coins-referral").innerHTML = "";
        document.getElementById("offer-details-coins-2nd-gen-referral").innerHTML = "";
        document.getElementById("offer-details-coins-3rd-gen-referral").innerHTML = "";
        document.getElementById("offer-details-unique-offer-id").value = "";
        document.getElementById("offer-details-unique-user-id").values = "";
        document.getElementById("share-offer-frame").className = "share-offer-frame hidden";
        document.getElementById("share-teaser-container").className = "share-teaser-container hidden";
        document.getElementById('offer-details-users-completed').className = "offer-details-completed-users-container";
    }

    static displayRecommendedOfferPopup(recommendedOfferID, handleOfferIsOpened) {

        let platform = Platform.getPlatformName();

        const req = ApiFacade.recommendedOffer(recommendedOfferID, null, platform);
        req.then(function (res) {
            let resultOK = res.ok;
            let result = res.response;
            if (resultOK) {
                if (result.offerAvailable) {
                    handleOfferIsOpened({ offer: result.recommendedOffer, isOpened: true });
                } else if (result.alternativeOffer) {
                    handleOfferIsOpened({ offer: result.alternativeOffer, isOpened: true });
                } else {
                    Alerts.showOfferNotAvailPopup(result.offerStatus);
                }
            }
        });
    }

    static displayOffersPolicy() {
        let swalHTML = '<h1 class="swal-offers-policy-title">' + Content.getValue("offers_policy_alert_title") + '</h1>';
        swalHTML += "<ol class='swal-offers-policy-text'>" + Content.getValue("offers_policy_alert_content") + "</ol>";

        MySwal.fire({
            html: swalHTML,
            background: '#141629',
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: Content.getValue("got_it_button_text"),
            customClass: {
                confirmButton: 'payment-method-add-confirm-button'
            }
        });
    }

    static filterAppOffers(allOffers, selectedHashTags, difficulty, selectedSortOption) {
        if (difficulty !== offerDifficulties.all) {
            allOffers.forEach(function (app) {
                app.offers = app.offers.filter(e => e.difficultyLevel === difficulty);
            });
        }

        selectedHashTags.forEach(function (hashTag) {
            allOffers = allOffers.filter(e => e.hashTags.includes(hashTag));
            allOffers.forEach(function (app) {
                app.offers = app.offers.filter(e => e.hashTags.includes(hashTag));
            });
        });

        allOffers = allOffers.filter(e => e.offers.length > 0);
        allOffers = this.adjustTotals(JSON.parse(JSON.stringify(allOffers)));
        allOffers = this.applySorting(JSON.parse(JSON.stringify(allOffers)), selectedSortOption);

        return allOffers;
    }

    static filterOffers(favouriteOffers, selectedHashTags, difficulty) {
        if (difficulty !== offerDifficulties.all) {
            favouriteOffers = favouriteOffers.filter(e => e.difficultyLevel === difficulty);
        }
        selectedHashTags.forEach(function (hashTag) {
            favouriteOffers = favouriteOffers.filter(e => e.hashTags.includes(hashTag));
        });

        return favouriteOffers;
    }

    static adjustTotals(offers) {
        offers.forEach(function (app) {
            let totalCoins = 0;
            let totalUsersParticipated = 0;
            let minimumDifficulty = offerDifficultiesToValues[offerDifficulties.hard];
            let timeAdded = 0;
            app.offers.forEach(offer => {
                totalCoins += offer.coins;
                totalUsersParticipated += offer.usersParticipated;
                if (offerDifficultiesToValues[offer.difficultyLevel] < minimumDifficulty) {
                    minimumDifficulty = offerDifficultiesToValues[offer.difficultyLevel];
                }
                if (offer.timeAdded > timeAdded) {
                    timeAdded = offer.timeAdded;
                }
            });
            app.totalCoins = totalCoins;
            app.totalUsersParticipated = totalUsersParticipated;
            app.difficultyLevel = Object.keys(offerDifficultiesToValues)[minimumDifficulty - 1];
            app.timeAdded = timeAdded;
        });

        return offers;

    }

    static applySorting(offers, selectedSortOption) {
        if (selectedSortOption === offerSortOptions.most_popular) {
            offers.sort((a, b) => (a.totalUsersParticipated < b.totalUsersParticipated ? 1 : b.totalUsersParticipated < a.totalUsersParticipated ? -1 : 0));
        }
        else if (selectedSortOption === offerSortOptions.highest_amount_offers) {
            offers.sort((a, b) => (a.totalCoins < b.totalCoins ? 1 : b.totalCoins < a.totalCoins ? -1 : 0));
        }
        else if (selectedSortOption === offerSortOptions.latest_offers) {
            offers.sort((a, b) => (a.latestTimeAdded < b.latestTimeAdded ? 1 : b.latestTimeAdded < a.latestTimeAdded ? -1 : 0));
        }

        return offers;
    }

    static showSpecial({ offers, selectedHashTags }) {
        const specialOffers = Offers.getBlurredOfferData(offers);

        const swalHTML = (
            <div className="special-offer-content desktop">
                <div className="special-offer-popup-title-container desktop flex justify-center items-center">
                    <h1 className="special-offers-modal-title margin-0">{ Content.getValue("special_offers") }</h1>
                    <h1 className="special-offers-modal-title margin-0">{ Content.getValue("@_treatcoin") }</h1>
                </div>
                <p className='swal-nfts-text desktop'>
                    { Content.getValue("special_offer_modal_description") }
                </p>
                <div>
                    {
                        specialOffers.map(item => (item.length===1) ?
                            (
                                <BlurProvider key={`_offer-container-${item.offers[0].ID}_${Date.now()}`} count={1}>
                                    <FeaturedOffer 
                                        key={`_offer-system-${item.offers[0].ID}_${Date.now()}`} 
                                        offer={item.offers[0]} 
                                        isSpecial={true}
                                        selectedHashTags={selectedHashTags} 
                                    />
                                </BlurProvider>
                            ) :
                                (item.length>1) ?
                                (
                                    <BlurProvider fullWidth key={`_offer-container-${item.offers[0].ID}_${Date.now()}`} count={item.length}>
                                        {item.offers.map(offer => (
                                            <FeaturedOffer 
                                                key={`_offer-system-${offer.ID}_${Date.now()}`} 
                                                offer={offer} 
                                                isSpecial={true}
                                                selectedHashTags={selectedHashTags} 
                                            />
                                        ))}
                                    </BlurProvider>
                                ) :
                                    (
                                        <FeaturedOffer 
                                            key={`_offer-system-${item.offers[0].ID}_${Date.now()}`} 
                                            offer={item.offers[0]} 
                                            isSpecial={true}
                                            selectedHashTags={selectedHashTags} 
                                        />
                                    )
                        )
                    }
                </div>
            </div>
        );

        const title = '<h1 class="special-offers-modal-title margin-0">' + (Content.getValue("special_offers")) + '</h1>' + 
        '<h1 class="special-offers-modal-title margin-top-0">' + (Content.getValue("@_treatcoin")) + '</h1>';

        MySwal.fire({
            title,
            imageUrl: Images.imageURL('robot-delete-account-icon.png'),
            html: swalHTML,
            background: 'linear-gradient(138.74deg, #8938B9 7.25%, #6219DE 65.79%)',
            showCloseButton: true,
            closeButtonHtml: '<img class="close-offer-icon" alt="Close offer details" width="47" height="47" src=' + Images.imageURL("close-offer-details.png") + ' />',
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                container: 'z-5 special-offers-container desktop',
                popup: 'special-offers-popup desktop',
                htmlContainer: "overflow-visible desktop",
                title: 'margin-top_80 special-offers-popup-swal-title mobile',
                image: 'special-offer-logo-image desktop',
                confirmButton: 'nfts-confirm-button',
                closeButton: 'swal2-nfts-close'
            }
        });
    }

    static getBlurredAppData(offers) {
        let start, isProcess=false, end, obj={}, result=[];
        for(let i=0; i<offers.length; i++){
            if(!offers[i].isAppInVisible) {
                if(isProcess) {
                    isProcess=false;
                    end=i-1;
                    obj[start]=end;
                    start=0; end=0;
                }
                obj[i]=-1;
            }
            else {
                if (i===offers.length-1) {
                    if(!isProcess) start=i;
                    end=i;
                    obj[start]=end;
                    start=0; end=0;
                }
                else {
                    if(isProcess) continue;
                    isProcess=true;
                    start=i;
                }
            }
        }

        Object.entries(obj).forEach(([key, value]) => {
            let final={};
            if(value===-1) {
                final.index=+key;
                final.apps=offers.slice(+key, +key+1);
            }
            else {
                final.index=+key;
                final.length=value-(+key)+1;
                final.apps=offers.slice(key, value+1);
            }
            result.push(final);
        })

        return result;
    }

    static getBlurredOfferData(offers) {
        let start, isProcess=false, end, obj={}, result=[];
        for(let i=0; i<offers.length; i++){
            if(offers[i].isAvailable) {
                if(isProcess) {
                    isProcess=false;
                    end=i-1;
                    obj[start]=end;
                    start=0; end=0;
                }
                obj[i]=-1;
            }
            else {
                if (i===offers.length-1) {
                    if(!isProcess) start=i;
                    end=i;
                    obj[start]=end;
                    start=0; end=0;
                }
                else {
                    if(isProcess) continue;
                    isProcess=true;
                    start=i;
                }
            }
        }

        Object.entries(obj).forEach(([key, value]) => {
            let final={};
            if(value===-1) {
                final.index=+key;
                final.offers=offers.slice(+key, +key+1);
            }
            else {
                final.index=+key;
                final.length=value-(+key)+1;
                final.offers=offers.slice(key, value+1);
            }
            result.push(final);
        })

        return result;
    }

    static hasSpecialOffers(offers) {
        return offers.some(each => each.offers.some(item => !item.isSpecial));
    }

    static getMultiStepsSum(multiSteps) {
        const multiStepsSum = multiSteps ?
            multiSteps.reduce((res, item) => {
                res.total += item.coins;
                if (item.isCompleted) res.current += item.coins;

                return res;
            }, {
                current: 0,
                total: 0,
            }) :
            null;

        return multiStepsSum;
    }
} 

export default Offers;