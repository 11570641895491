import UserData from 'User_Data.js';
import { OfferPopupContext } from 'context/Offer_Popup_Context.js';
import { useContext, useEffect, useState } from 'react';
import Content from 'utils/Content.js';
import Formatting from 'utils/Formatting.js';
import Images from 'utils/Images.js';
import Joining from "utils/Joining.js";
import OfferEvents from "utils/Offer_Events.js";
import Platform from 'utils/Platform.js';
import OfferReportStatus from 'components/offers/Offer_Report_Status.js';
import OfferShareButton from 'components/offers/popup/Offer_Share_Button.js';
import jwt from 'jwt-decode';
import ReportForm from './Report_Form.js';
import ActionButton from 'components/buttons/Action_Button.js';
import IsOverflown from 'utils/IsOverflow.js';
import LinkCreator from 'utils/Link_Creator.js';
import ApiFacade from 'ApiFacade.js';
import isTimeDiffBiggerInterval from 'utils/Check_Time_Diff.js';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import VideoGuide from './Video_Guide.js';
import { useNavigate } from 'react-router-dom';
import StockPopup from './Stock_Popup.js';
import ReviewItem from './Review_Item.js';
import { Alert } from '@mui/material';
import PhoneVerificationPopup from 'popups/PhoneVerificationPopup.js';
import PopupModal from 'components/modals/PopupModal.js';
import Alerts from '../../../utils/Alerts.js';

const configuration = require("configuration.json");


const eventTypes = configuration.event_types;
const userDataKeys = configuration.user_data_keys;
const businessLogic = configuration.business_logic;
const offerMainType = configuration.offer_main_type;
const systemOfferURLs = configuration.system_offer_urls;
const phoneVerificationOfferUniqueID = configuration.unique_offers.phone_verification;

const linkedOffersKey = userDataKeys.linked_offers;
const linkedOffersInterval = businessLogic.linked_offers_interval;

const discordOfferLink = LinkCreator.createDiscordLink();
const botOfferLink = LinkCreator.createBotLink();

function calculateFontSize(coins) {
    return coins > 10000 ? 11 : coins > 1000 ? 13 : coins > 100 ? 15 : 16;
}

const Category = ({ category }) => {
    const { key, image } = category;
    return (
        <li className="offer-popup-category">
            <img
                className='offer-popup-category-img'
                src={image}
            />
            {Formatting.shortenText(Content.getValue(key), 20)}
        </li>
    );
};

const Reward = ({ index, value }) => {
    return <li className="offer-popup-reward">
        <p className="offer-popup-white-heading-14 text-12-i">{Content.getValue(`get`)}</p>
        <img width={20} height={20} src={Images.imageURL('offers/stats-header-money-icon-new.png')} className="top-offer-homepage-treat-coin" />
        <p className="offer-popup-white-heading-14 text-16-i">{value}</p>
        <p className="offer-popup-grey-text-12">{Content.getValue(`get_gen_complete_${index + 1}`)}</p>
    </li>;
};

const LinkedOffer = ({ offer, handleLinkedOfferClick }) => {
    const { coins, MainTitle, Completed, } = offer;
    let fontSize = calculateFontSize(coins);
    return <li className="offer-popup-multistep cursor-pointer "
        onClick={() => { handleLinkedOfferClick(offer); }}
    >
        <div className="offer-popup-multistep-value">
            <img width={20} height={20} src={Images.imageURL('offers/stats-header-money-icon-new.png')} className="top-offer-homepage-treat-coin" />
            <p
                style={{ fontSize: fontSize }}
            >
                {coins?.toLocaleString()}
            </p>
        </div>
        <div className="offer-popup-multistep-right padding-right-20 ">
            <p className={`offer-popup-grey-text-12 ${Completed ? 'underlined-text' : ''}`}>{MainTitle}</p>
            {Completed === 1 &&
                <img width={16} height={16} src={Images.imageURL('offers/completed4x.png')} className="offer-popup-multistep-completed" />
            }
        </div>
    </li>;
};

const MultiEvent = ({ step }) => {
    const { coins, desc, isCompleted } = step;
    let fontSize = calculateFontSize(coins);
    return <li className="offer-popup-multistep">
        <div className="offer-popup-multistep-value">
            <img width={20} height={20} src={Images.imageURL('offers/stats-header-money-icon-new.png')} className="top-offer-homepage-treat-coin" />
            <p
                style={{ fontSize: fontSize }}
            >
                {coins?.toLocaleString()}
            </p>
        </div>
        <div className="offer-popup-multistep-right">
            <p className="offer-popup-grey-text-12 ">{desc}</p>
            {isCompleted &&
                <img width={16} height={16} src={Images.imageURL('offers/completed.png')}

                    className="offer-popup-multistep-completed" />
            }
        </div>
    </li>;
};

const Guide = ({ Guide }) => {
    const { type, value } = Guide;
    const [imageSize, setImageSize] = useState({ width: null, height: null });

    const handleImageLoad = (event) => {
        const intrinsicWidth = event.target.naturalWidth;
        const intrinsicHeight = event.target.naturalHeight;
        setImageSize({ width: intrinsicWidth, height: intrinsicHeight });
    };
    return (
        <li className={`offer-popup-guide ${type === 1 && 'flex-column-centered'}`}>
            {type === 1 ? (
                <img
                    onLoad={handleImageLoad}
                    src={value}
                    className='offer-popup-image'
                    style={{
                        width: '100%',
                        height: `${100*imageSize.height/imageSize.width}%`,
                        maxWidth: `${imageSize.width}px`,
                    }}
                />
            ) : type === 0 ? (
                // Content for type 0
                <p className="offer-popup-grey-text-12"  dangerouslySetInnerHTML={{__html: value.replaceAll("\\n","<br>")}}/>
            ) : <></>}
        </li>
    );
};


function OfferPopup() {
    const navigate = useNavigate();
    const { isOpenedOfferPopup, handleOfferIsOpened, offer } = useContext(OfferPopupContext);
    const [guidesOpened, setGuidesOpened] = useState(false);
    const [descExpanded, setDescExpanded] = useState(false);
    const [openReport, setOpenReport] = useState(false);
    const [stickyOffset, setStickyOffset] = useState(10);
    const [errorOpened, setErrorOpened] = useState(false);
    const [showShowMoreBtn, setShowShowMoreBtn] = useState(false);
    const [linkedOffers, setLinkedOffers] = useState(offer?.hasLinkedOffers === 1);
    const [earnBtnDisabled, setEarnBtnDisabled] = useState(false);
    const [openStockPopup, setOpenStockPopup] = useState(false);
    const [chosenCombination, setChosenCombination] = useState(null);
    const [openReviews, setOpenReviews] = useState(false);
    const [reviews, setReviews] = useState(null)
    const [showPhoneVerificationPopup, setShowPhoneVerificationPopup] = useState(false)
    const [phoneVerificationSuccessful, setPhoneVerificationSuccessful] = useState(false);

    const handleOpenStockPopup = (e) => {
        e.stopPropagation()
        setOpenStockPopup(prev => !prev);
    }

    const getLinkedOffers = ({ platformName, uniqueOfferID }) => {
        const getLinkedOffersReq = () => {
            ApiFacade.getLinkedOffers({ platformName, uniqueOfferID }).then(res => {
                const offers = res.response;
                setLinkedOffers(offers.length ? offers.filter(offer => offer.uniqueID !== uniqueOfferID) : null);
                if (offers.length) {
                    const timestamp = Date.now();
                    linkedOffersData = { ...linkedOffersData };
                    offers.forEach((offer) => {
                        linkedOffersData[offer.uniqueID] = { offers, timestamp };
                    });
                    UserData.setItem(linkedOffersKey, JSON.stringify(linkedOffersData));
                }
            });
        };

        let linkedOffersData = JSON.parse(UserData.getItem(linkedOffersKey));
        if (linkedOffersData !== null && linkedOffersData[uniqueOfferID]) {
            if (!isTimeDiffBiggerInterval(linkedOffersData[uniqueOfferID].timestamp, linkedOffersInterval)) {
                setLinkedOffers(linkedOffersData[uniqueOfferID].offers.filter(offer => offer.uniqueID !== uniqueOfferID));
            } else if (offer?.hasLinkedOffers) {
                getLinkedOffersReq();
            }
        } else if (offer?.hasLinkedOffers) {
            getLinkedOffersReq();
        }
    };

    let platform = Platform.getPlatformName();
    const [reportDisabled, setReportDisabled] = useState();

    const handleReportDisabled = () => {
        if (!ApiFacade.isLoggedIn()) {
            setReportDisabled(true);
        } else {
            setReportDisabled(offer?.ClickthroughID !== null ? false : true);
        }
    };
    const handleClose = () => {
        setGuidesOpened(false);
        handleReportDisabled();
        handleOfferIsOpened({ isOpened: false });
        setOpenReport(false);
        setErrorOpened(false);
        setEarnBtnDisabled(false);
        setOpenStockPopup(false);
        setChosenCombination(null);
        setReviews(null);
        setOpenReviews(false);
        setShowPhoneVerificationPopup(false);
    };
    const handleLinkedOfferClick = (offer) => {
        handleClose();
        handleOfferIsOpened({ offer });
        getLinkedOffers({ platformName:platform, uniqueOfferID: offer.uniqueID });
    };
    const handleGetReviews = async () => {
        try {
            const res = await ApiFacade.getOfferReviews({ reviewTopicID: offer?.reviewTopicID });
            setReviews(res?.response?.reviews);
        } catch (error) {
            Alert.showError(error.message);
        }
    }

    useEffect(() => {
        if (offer?.reviewAllowed && isOpenedOfferPopup) {
            handleGetReviews();
        }
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        };

        if (isOpenedOfferPopup) {
            setShowShowMoreBtn(IsOverflown(document.getElementById('show-more-less-content')));
            window.addEventListener('keydown', handleKeyPress);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [isOpenedOfferPopup, handleOfferIsOpened]);
    
    useEffect(() => {
        handleReportDisabled();
        setLinkedOffers(offer?.hasLinkedOffers === 1);
        getLinkedOffers({ platformName: platform, uniqueOfferID: offer?.uniqueID });
        if (isOpenedOfferPopup && !openReport) {
            const offerPopupScrolls = document.getElementById('offer-popup-scrolls');
            const offerPopup = document.getElementById('offer-popup');
            setStickyOffset(offerPopup.offsetHeight - window.innerHeight - offerPopupScrolls.scrollTop + 120);
            const handleScroll = () => {
                setStickyOffset(offerPopup.offsetHeight - window.innerHeight - offerPopupScrolls.scrollTop + 120);
            };
            offerPopupScrolls.addEventListener('scroll', handleScroll);
            return () => offerPopupScrolls.removeEventListener('scroll', handleScroll);
        }
    }, [isOpenedOfferPopup, openReport]);

    if (isOpenedOfferPopup && offer) {
        let {
            imageURL,
            appName,
            MainTitle,
            coins,
            CategoryImageURL,
            description,
            coinsReferral,
            coins2ndGenReferral,
            coins3rdGenReferral,
            treatCoinValue,
            Guides,
            ReportStatus,
            multiSteps,
            isSystem,
            offerStartType,
            ForeignTicketID,
            url,
            CategoryHPID,
            GuidesImageURL,
            GuidesVideoURL,
            disableSubmitReport,
            IsStockOffer,
            AttributeMeaning1,
            AttributeMeaning2,
            uniqueID,
            CombinationID,
            ClickthroughID,
            newCoins,
            reviewAllowed,
            rating,
            isGold,
            isPercentage,
            cookingRequired,
            isAvailable,
        } = offer;
        multiSteps = multiSteps && JSON.parse(multiSteps);
        Guides = JSON.parse(offer?.Guides);
        const langForUrl =
            UserData.getItem(userDataKeys.language)?.slice(0, 2) !== 'en'
                ? '/' + UserData.getItem(userDataKeys.language)?.slice(0, 2)
                : '';

        const earnButtonHandler = async (stockCombinationID) => {
            if (!ApiFacade.isLoggedIn()) {
                Joining.showPopup(navigate, handleClose);
            } else {
                if (isAvailable) {
                    setEarnBtnDisabled(true);
                    let link = url;
                    let directLink = false;
                    let redirectURLAfterEventRecorded = null;
                    if (window.location.pathname === `${langForUrl}${link}`) {
                        setErrorOpened(false);
                        handleOfferIsOpened({ isOpened: false });
                    } else if (link === configuration.system_offer_urls.refer_friends) {
                        document.getElementById('one-two-treat-button').click();
                        setErrorOpened(false);
                        handleOfferIsOpened({ isOpened: false });
                    } else if (offer.uniqueID == phoneVerificationOfferUniqueID) {
                        return setShowPhoneVerificationPopup(true);
                    } else {

                        if (!isSystem) {
                            redirectURLAfterEventRecorded = link;
                        }
                        else {
                            if (link === systemOfferURLs.connect_to_discord) {
                                link = discordOfferLink;
                                directLink = true;
                            }
                            else if (link === systemOfferURLs.connect_to_bot) {
                                link = botOfferLink;
                                directLink = true;
                            }
                            else {
                                redirectURLAfterEventRecorded = `${langForUrl}${link}`;
                            }
                        }
                    }
                    const eventSender = async () => {
                        const closePopup = () => handleOfferIsOpened({ isOpened: false });
                        await OfferEvents.sendEvent({
                            offerStartType,
                            offerID: offer.ID,
                            eventType: eventTypes.clickthrough,
                            appName: offer.appName,
                            category: offer.category,
                            offerType: offer.offerType,
                            UserPayout: Number(UserData.getItem(userDataKeys.offer_coins_amount)),
                            isFree: offer.isFree,
                            platformName: Platform.getPlatformName(),
                            offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id),
                            uniqueID: offer.uniqueID,
                            url: offer.url,
                            providerID: offer.providerID,
                            isSystem: offer.isSystem,
                            stockCombinationID,
                        },
                            redirectURLAfterEventRecorded,
                            closePopup,
                        );
                    };
                    if (directLink === false) {
                        await setReportDisabled(false);
                        await eventSender();
                    }
                    else {
                        await eventSender();
                        window.location.href = link;
                    }
                    setEarnBtnDisabled(false);
                } else {
                    const res = await ApiFacade.notifyOfferAvail(uniqueID);
                    try {
                        
                        if (res.response.code === 1) {
                            Alerts.showSuccessPopup({
                                confirmBtnText: Content.getValue('gold_popup_btn'),
                                desc: Content.getValue(res.response.message),
                                head: Content.getValue('offer_popup_notify_avail_head'),
                            });
                        } else {
                            Alerts.showErrorPopup({
                                confirmBtnText: Content.getValue('gold_popup_btn'),
                                desc: Content.getValue(res.response.message), 
                            });
                        }
                    } catch (error) {
                        Alerts.showErrorPopup({
                            confirmBtnText: Content.getValue('gold_popup_btn'),
                            desc: Content.getValue('api_response_general_error'),
                        });
                    }
                }


            }
        };

        const handleOpenSubmitForm = ({ open = false }) => {
            if (open === true) {
                const offerPopupScrolls = document.getElementById('offer-popup-scrolls');
                if (offerPopupScrolls) {
                    offerPopupScrolls.removeEventListener('scroll', () => {

                    });
                }
            }
            setOpenReport(open);
        };

        let refRewards = [];
        if (coinsReferral > 0) {
            refRewards.push(coinsReferral);
        }
        if (coins2ndGenReferral > 0) {
            refRewards.push(coins2ndGenReferral);
        }
        if (coins3rdGenReferral > 0) {
            refRewards.push(coins3rdGenReferral);
        }
        return (
            <div className='fixed-popup-wrapper' id='offer-popup-scrolls'
                style={{ display: isOpenedOfferPopup ? 'block' : 'none' }}
            >
                <div
                    className={`additional-offers-head-img`}
                    style={{ backgroundImage: `url(${Images.imageURL(CategoryImageURL)})` }}
                    />
                <div className="offer-popup-category-sticker">
                    {(CategoryImageURL && CategoryHPID)
                        ?
                        <Category category={{
                            image: Images.imageURL(CategoryImageURL),
                            key: `additional_offers_heading_${offerMainType[CategoryHPID]}`,
                        }} />
                        : <></>
                    }
                </div>
                <div id='offer-popup' className={`offer-popup ${isGold == true ? 'is-gold' : ''}`} onClick={(e) => { e.stopPropagation(); }}>

                    <button className="button-hidden close-button"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <img width={32} height={32} src={Images.imageURL('close-welcome.png')} />
                    </button>
                    {openReport ?
                        <ReportForm
                            ProviderID={offer?.ProviderID}
                            uniqueOfferID={offer?.uniqueID}
                            handleOpenSubmitForm={handleOpenSubmitForm}
                            handleError={() => {
                                setTimeout(() => {
                                    setErrorOpened(true);
                                }, 1000);
                            }}
                            offerName={appName}
                        />
                        :
                        <>
                            <div className="offer-popup-head ">
                                <div>
                                    <div
                                        style={{ backgroundImage: `url(${imageURL})` }}
                                        className='offer-popup-head-image'
                                    />
                                </div>
                                <div className="offer-popup-head-right">
                                    <p className="offer-popup-head-right-appname ">{MainTitle}</p>
                                    <p className="offer-popup-head-right-maintitile">{appName}</p>
                                    <div className="offer-popup-grey-container padding-0 padding-top-10 max-w-fit-content">
                                        {isPercentage ?
                                            <>
                                                <p className="top-offer-homepage-tc-value padding-x-10">{Formatting.format(Content.getValue('offer_popup_percentage_value'), ['(value)'], [coins])}</p>
                                            </>
                                            : <>
                                                <div className="top-offer-homepage-payout padding-x-10">
                                                    <img width={20} height={20} src={Images.imageURL('offers/stats-header-money-icon-new.png')} className="top-offer-homepage-treat-coin" />
                                                    <p className="top-offer-homepage-tc-value">{chosenCombination?.Coins || coins}</p>
                                                    <div className='top-offer-homepage-usd-value-container'>
                                                        <p className="top-offer-homepage-usd-value">=  {Formatting.currencySymbol()} {((chosenCombination?.Coins || coins) * treatCoinValue).toFixed(2)}</p>
                                                        <p className="top-offer-homepage-usd-desc">{Formatting.currencyLabel()}</p>
                                                    </div>
                                                </div>
                                                <p className="top-offer-homepage-tc-desc padding-x-10">{isGold ? 'CP' : 'Treat Coins'}</p>
                                                {cookingRequired ?
                                                <div className='offer-popup-coins-when-pays-container'>
                                                    <p className="offer-popup-coins-when-pays-text">{Content.getValue('offer_popup_when_partner_pays')}</p>
                                                    <img src={Images.imageURL('lucide_info.png')} className='offer-popup-coins-when-pays-image' />
                                                    <div className="offer-popup-coins-when-pays-tooltip">
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: Content.getValue('offer_popup_when_partner_pays_tooltip'),
                                                                }}
                                                            ></p>
                                                        <div className="offer-popup-coins-when-pays-tooltip-arrow"></div>
                                                    </div>
                                                </div>
                                                    : <></>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {(IsStockOffer && !ClickthroughID && !newCoins && ApiFacade.isLoggedIn()) ?
                                <button className="hidden-button w-full offer-popup-grey-container offer-popup-white-heading-14 flex justify-between"
                                    onClick={handleOpenStockPopup}
                                >
                                    {Formatting.format(Content.getValue("stock_offers_open_popup"), ["(att1)", "(att2)"], [AttributeMeaning1, AttributeMeaning2])}
                                    <img width='21' height='21' src={Images.imageURL('offers/stock_offers.png')}  />
                                </button>
                                : <></>
                            }
                            {(GuidesVideoURL && ApiFacade.isLoggedIn())? <VideoGuide
                                eventData={{
                                    offerID: offer.ID,
                                    appName: offer.appName,
                                    category: offer.category,
                                    offerType: offer.offerType,
                                    UserPayout: Number(UserData.getItem(userDataKeys.offer_coins_amount)),
                                    isFree: offer.isFree,
                                    platformName: Platform.getPlatformName(),
                                    offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id),
                                    uniqueID: offer.uniqueID,
                                    url: offer.url,
                                    providerID: offer.providerID,
                                    isSystem: offer.isSystem,
                                }}
                                video={GuidesVideoURL} image={GuidesImageURL} offer={offer} />
                                : <></>}
                            <div className="offer-popup-grey-container">
                                <p className="offer-popup-white-heading-14">{Content.getValue('description')}</p>
                                <p id='show-more-less-content' className={`offer-popup-grey-text-12 text-limit-3rows ${descExpanded && 'desc-expanded'}`}>{description}</p>
                                {showShowMoreBtn ?
                                    <button
                                        onClick={() => { setDescExpanded(prev => !prev); }}
                                        className="hidden-button w-full flex justify-end">
                                        <span className="offer-popup-desc-show-more-text">{Content.getValue(descExpanded ? 'show_less' : 'show_more')}</span>
                                        <img
                                            className='offer-popup-desc-arrow'
                                            style={{ transform: descExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                            src={Images.imageURL('arrow-shevron-purple.png')} />
                                    </button>
                                    : <></>
                                }
                                {reviewAllowed && rating ?
                                    <div className="offer-popup-reviews">
                                        <div className="flex">
                                            <img className='offer-popup-reviews-rating-star' src={Images.imageURL('offer-reviews/star.png')}  />
                                            <p className="offer-popup-reviews-rating">{rating}</p>
                                        </div>
                                        <button
                                            onClick={() => setOpenReviews(prev => !prev)}
                                            className="hidden-button top-offer-homepage-tc-desc underlined-text">
                                            {Content.getValue(openReviews ? 'show_less' : 'see_all_reviews')}
                                        </button>
                                        <ul className={`offer-popup-reviews-list ${openReviews ? 'open' : ''}`}>
                                            {reviews?.map(review =>
                                                <ReviewItem key={review.ID} review={review} />
                                            )}
                                        </ul>
                                    </div>
                                    : <></>}
                            </div>
                            {coinsReferral > 0 &&
                                <div className="offer-popup-grey-container">
                                    <p className="offer-popup-white-heading-14 margin-bottom-10 ">{Content.getValue('rewards_when_complete')}</p>
                                    <ul className="offer-popup-rewards-list">
                                        {[
                                            coinsReferral = chosenCombination?.CoinsReferral || coinsReferral,
                                            coins2ndGenReferral = chosenCombination?.Coins2ndGenReferral || coins2ndGenReferral,
                                            coins3rdGenReferral = chosenCombination?.Coins3rdGenReferral || coins3rdGenReferral,
                                        ]
                                            .map((value, index) => <Reward key={`Reward-${index}`} value={value} index={index} />)}
                                    </ul>
                                </div>
                            }
                            {multiSteps &&
                                <div className="offer-popup-grey-container">
                                    <p className="offer-popup-white-heading-14 margin-bottom-10">{Content.getValue('multi_events')}</p>
                                    <ul className="offer-popup-list">
                                        {multiSteps.map((step) => <MultiEvent key={step.ID} step={step} />)}
                                    </ul>
                                </div>
                            }
                            <div className={`offer-popup-grey-container  `} style={{ display: linkedOffers ? 'block' : 'none' }}>
                                <p className="offer-popup-white-heading-14 margin-bottom-10">{Content.getValue('linked_offers')}</p>
                                <SimpleBar style={{ maxHeight: 155 }}>
                                    <ul className={`offer-popup-list linked-offers ${linkedOffers ? 'displayed' : ''}`}>
                                    {linkedOffers?.length && linkedOffers?.map((offer) => <LinkedOffer
                                        handleLinkedOfferClick={handleLinkedOfferClick}
                                        key={offer.ID} offer={offer} />)}
                                </ul>
                                </SimpleBar>
                            </div>


                            {Guides
                                &&
                                <div className='offer-popup-grey-container'>
                                    <button
                                        onClick={() => {
                                            setGuidesOpened(prev => !prev);
                                        }}
                                        className="button-hidden offer-popup-guides">
                                        <span className='offer-popup-white-heading-14 '>{Content.getValue('guides')}</span>
                                        <img
                                            className='offer-popup-guides-arrow'
                                            style={{ transform: guidesOpened ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                            src={Images.imageURL('arrow-shevron.png')} />
                                    </button>
                                    <div
                                        style={{
                                            // padding: guidesOpened ? 10 : 0,
                                            borderWidth: guidesOpened ? 1 : 0,
                                            height: guidesOpened ? document.getElementById('offer-popup-expanded-list')?.clientHeight : 0,
                                            maxHeight: guidesOpened ? document.getElementById('offer-popup-expanded-list')?.clientHeight : 0,
                                        }}
                                        className={`show-more-container ${guidesOpened ? 'expanded ' : 'collapsed'}`}>
                                        <ul
                                        id="offer-popup-expanded-list"
                                        className="offer-popup-list margin-top-10 padding-bottom-10">
                                            {Guides.map(guide => <Guide key={`Guide-${guide.value}`} Guide={guide} />)}
                                        </ul>
                                    </div>
                                </div>
                            }
                            {
                                (ApiFacade.isLoggedIn()) ? (
                                <OfferShareButton
                                 userID={jwt(UserData.getItem(userDataKeys.jwt_token)).uniqueUserID}
                                 offerID={offer.uniqueID}
                                 imageURL={offer.imageURL}
                                 appName={offer.appName}
                                 offerDescription={offer.MainTitle}
                                 
                                />
                                ): (null)
                                }
                            <div className={`offer-popup-sticky-bottom`}
                                style={{ bottom: `${stickyOffset}px` }}
                            >
                                {isSystem || disableSubmitReport  ?
                                <></>
                                :
                                <OfferReportStatus
                                    disableBtn={reportDisabled}
                                    iconSize={40}
                                    handleOpenSubmitForm={handleOpenSubmitForm}
                                    ReportStatus={ReportStatus}
                                    possibleToSubmit={true}
                                    reportContentKey={ReportStatus === null ? 'not_sent' : ReportStatus}
                                    ForeignTicketID={ForeignTicketID}
                                />
                                }
                                <div className={`show-more-container ${errorOpened ? 'expanded ' : 'collapsed'}`}>
                                    <p className={`offer_popup_error_text `}>
                                        {Content.getValue('offer_popup_submit_report_error')}
                                    </p>
                                </div>
                                <ActionButton
                                    classNames={isGold ? 'is-gold-button' : ''}
                                    disabled={earnBtnDisabled}
                                    onClick={() => {
                                        earnButtonHandler(chosenCombination?.ID || CombinationID)
                                    }}
                                    content={(!isAvailable && ApiFacade.isLoggedIn())? Content.getValue('offer_popup_earn_btn_coins_is_avail') : (
                                        isPercentage ?
                                        Formatting.format(Content.getValue('offer_popup_earn_btn_coins_percentage_value'), ['(value)'], [coins])
                                        :
                                        Formatting.format(Content.getValue("offer_popup_earn_btn_coins"), ["(coins)"], [chosenCombination?.Coins || coins])
                                    )
                                    }
                                />
                            </div>
                        </>
                    }
                </div>
                {IsStockOffer && !ClickthroughID && !newCoins && ApiFacade.isLoggedIn() ?
                    <StockPopup
                        chosenCombination={chosenCombination}
                        setChosenCombination={setChosenCombination}
                        AttributeMeaning1={AttributeMeaning1}
                        AttributeMeaning2={AttributeMeaning2}
                        CombinationID={CombinationID}
                        uniqueID={uniqueID}
                        handleOpenStockPopup={handleOpenStockPopup}
                        openStockPopup={openStockPopup}
                        defaultCombination={{
                            Coins: coins,
                            treatCoinValue,
                        }}
                        earnButtonHandler={earnButtonHandler}
                    /> : <></>
                }

                <PopupModal 
                    isOpened={showPhoneVerificationPopup} 
                    closeModal={() => {
                        if (phoneVerificationSuccessful) {
                            handleOfferIsOpened({ isOpened: false });
                            setShowPhoneVerificationPopup(false);
                            redirectWithLangFunc(configuration.paths.home, navigate);
                        }
                        else {
                            setShowPhoneVerificationPopup(false);
                            setEarnBtnDisabled(false);
                        }
                    }}
                    className='border-rounded max-w-600 overflow-none'
                >
                    <PhoneVerificationPopup 
                        setPhoneVerificationSuccessful={setPhoneVerificationSuccessful}
                        onComplete={()=>{
                            handleOfferIsOpened({ isOpened: false });
                            setShowPhoneVerificationPopup(false);
                            redirectWithLangFunc(configuration.paths.home, navigate);
                        }}
                    />
                </PopupModal>
            </div>
        );
    } else {
        return <></>;
    }
}

export default OfferPopup;