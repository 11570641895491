import React, { useEffect, useRef, useState } from 'react';
import Content from 'utils/Content';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TopOfferHomepageCard from './Top_Offer_Homepage_Card';
import ApiFacade from 'ApiFacade.js';
import JWTUtils from 'utils/JWT_Utils.js';
import Platform from 'utils/Platform';
import { Spinner } from 'react-spinners-css';
import Formatting from 'utils/Formatting';
import Images from 'utils/Images';

const configuration = require('configuration.json');

function TopOfferHomepageList() {
  const scrollRef = useRef(null);
  const [items, setItems] = useState(null);
  const [authorized, setAuthorized] = useState(false);

  const handleScroll = (scrollOffset) => {
    if (scrollRef.current) {
      const element = scrollRef.current;
      element.scrollBy({
        left: scrollOffset,
        behavior: 'smooth' // Adds smooth scrolling behavior
      });
    }
  };

  useEffect(() => {
    let platform = Platform.getPlatformName();
    let preferredLanguage = Content.getPreferredLanguage();
    let currentCountry = JWTUtils.getJWTCountry();

    const req = ApiFacade.top12Offers(preferredLanguage, currentCountry, platform);
    req.then( (res) => {
      let result = res.response;
      setAuthorized(ApiFacade.isLoggedIn());
      setItems(result?.offers);
    });

  }, []);

  return (
    <div className={`top-offers-homepage-container ${authorized && 'top-offers-homepage-container-mx-width'}`}>
      <div className="top-offers-homepage-header">
        <h3 className="top-offers-homepage-name">
          {Formatting.format(Content.getValue("earn_from_top_12"), ["(offers)"], [items?.length || ''])}
        </h3>
        <RedirectWithLang
          to={configuration.paths.top_12_offers}
          className="top-offers-homepage-btn hidden-button "
          text={Content.getValue('full_view')}
          isButton={true}
        >
          <KeyboardArrowRightIcon fontSize='large' />
        </RedirectWithLang>
      </div>
      <ul ref={scrollRef} className='top-offers-homepage-list'
        // onClick={() => {
        //   if (!authorized) {
        //     return Joining.showPopup(navigate);
        //   }
        // }}
      >
        {items ?
          items.map(item => <TopOfferHomepageCard key={item.ID} offer={{ ...item, authorized }} />)
          : <div className='flex justify-center items-center w-full'>
            <Spinner color="#6BC6F5" />
          </div>
        }
      </ul>
      <button className='hidden-button top-offers-homepage-scroll-left' onClick={() => handleScroll(-400)}>
        <img width={20} height={20} src={Images.imageURL('offers/scroll-left.png')} alt="ScrollLeft" />
      </button>
      <button className='hidden-button top-offers-homepage-scroll-right' onClick={() => handleScroll(220)}>
        <img width={20} height={20} src={Images.imageURL('offers/scroll-right.png')} alt="ScrollLeft" />
      </button>
    </div>
  );
}

export default TopOfferHomepageList;