import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import JWTUtils from 'utils/JWT_Utils.js';
// import SeeOffersButton from 'components/buttons/See_Offers_Button.js';
import FeaturedOffer from 'components/offers/Featured_Offer.js';
import IncompleteOffer from 'components/offers/Incomplete_Offer.js';
import OfferDifficultyIndicator from 'components/offers/Offer_Difficulty_Indicator.js';
import OfferThemeIndicator from 'components/offers/Offer_Theme_Indicator.js';
import BlurProvider from 'components/offer_providers/Blur_Provider.js';
import ScrollToTop from 'components/widgets/Scroll_To_Top.js';
import React from 'react';
import { Spinner } from 'react-spinners-css';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';
import Formatting from 'utils/Formatting.js';
import Images from 'utils/Images.js';
import Offers from 'utils/Offers.js';
import OfferEvents from 'utils/Offer_Events.js';
import Platform from 'utils/Platform.js';
import { Mixpanel } from 'utils/User_Events_Util.js';

import { OfferPopupContext } from 'context/Offer_Popup_Context.js';

const configuration = require('configuration.json');

let thisComponent;
let markedDifficultyId = 'all-offers';

let allOffers = [];
// let allFavouriteOffers = [];
// let allSpecialOffers = [];

const eventTypes = configuration.event_types;
const incompleteOfferModes = configuration.incomplete_offer_modes;
const featuredOfferModes = configuration.featured_offer_modes;
const offerSortOptions = configuration.offer_sort_options;
const offerDifficulties = configuration.offer_difficulties;
const userDataKeys = configuration.user_data_keys;
const mixpanelEvents = configuration.mixpanel_events;
const firstHashtags = configuration.first_hashtags;
const businessLogic = configuration.business_logic;

const offersToShowInitially = businessLogic.offers_to_show_initially;

let jwtToken = UserData.getItem(userDataKeys.jwt_token);

class IncompleteOffersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authorized: Boolean(jwtToken),
            error: null,
            isLoaded: false,
            offers: [],
        //    favouriteError: null,
        //  isFavouriteLoaded: false,
        //  isSpecialLoaded: false,
        //  favouriteOffers: [],
            hashTags: [],
            selectedHashTags: [],
            selectedDifficulty: offerDifficulties.all,
            sortOptions: [
                offerSortOptions.most_popular,
                offerSortOptions.offers_for_you,
                offerSortOptions.highest_amount_offers,
                offerSortOptions.latest_offers
            ],
            selectedSortOption: null,
            isOpenSortOptions: false,
            isSortingOn: false,
            todaysBest: null,
            displayAll: (window.innerWidth >= 1024)? true: false
        };
        this.impressionsSent = [];
        thisComponent = this;
    }

    static contextType = OfferPopupContext;

    updateOffersList() {
        thisComponent.setState({
            ...thisComponent.state,
            isLoaded: false,
            offers: []
        });

        let newApps = thisComponent.filterAndSort(allOffers);
        thisComponent.setState({
            ...thisComponent.state,
            isLoaded: true,
            offers: newApps
        });
    }

    displayRecommendedOffer() {
        const { handleOfferIsOpened } = this.context;
        let recommendedOffer = UserData.getItem(userDataKeys.recommended_offer);
        let emailOffer = Number(UserData.getItem(userDataKeys.email_offer));

        if(ApiFacade.isLoggedIn()) {
            if (recommendedOffer) {
                Offers.displayRecommendedOfferPopup(recommendedOffer, handleOfferIsOpened);
                UserData.removeItem(userDataKeys.recommended_offer);
                UserData.removeItem(userDataKeys.offer_recommender);
            }
            else if (emailOffer) {
                // const commonAndSpecialOffers = allOffers.concat(allSpecialOffers);
                const offer = allOffers
                .flatMap(obj => obj.offers)
                .find(offer => (offer.ID === emailOffer && offer.isAvailable));
                if (offer) {
                    handleOfferIsOpened({ offer });
                }
                UserData.removeItem(userDataKeys.email_offer);
            }
        }
    }

    loadComponent() {        
        let existingHashtags, allHashtags;
        let hashList = document.getElementById('offers-list-hash-tags');
        if (hashList !== null) hashList.scrollLeft = 0;

        let platform = Platform.getPlatformName();
        let preferredLanguage = Content.getPreferredLanguage();
        let currentCountry = JWTUtils.getJWTCountry();

        const req = ApiFacade.offers(preferredLanguage, currentCountry, platform);

        req.then(function (res) {
                if (res.ok){
                    let result = res.response;
                    allOffers = result.apps;
                    if (ApiFacade.isLoggedIn()) {
                        thisComponent.sendImpressionsEvent(result.apps.slice(0, offersToShowInitially));
                    }
                    UserData.setItem(userDataKeys.offer_completion_disclaimer_displayed, result.coinsDisclaimerDisplayed);

                    thisComponent.setState(prev => {
                        existingHashtags = prev.hashTags || [];
                        const hashtags = Array.from(new Set(existingHashtags.concat(result.hashTags)));
                        allHashtags = [...firstHashtags, ...hashtags].filter((value, index, self) => {
                            return self.indexOf(value) === index;
                        });

                        return {
                            ...prev,
                            isLoaded: true,
                            offers: result.apps,
                            hashTags: allHashtags,
                            selectedHashTags: [],
                            todaysBest: result.todaysBest
                        }
                    });
                } else {
                    thisComponent.setState({
                        ...thisComponent.state,
                        isLoaded: true,
                        error: res.error
                    });
                }
            },
                (error) => {
                    this.setState({
                        ...thisComponent.state,
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    beforeUnloadHandler() {
        if (UserData.getItem(userDataKeys.offer_wall_visit_id) !== null) {
            window.removeEventListener("beforeunload", thisComponent.beforeUnloadHandler);
            OfferEvents.storeEventsOnRefresh();
        }
    }

    componentDidMount() {
        thisComponent.loadComponent();
        if (ApiFacade.isLoggedIn()) {
            // thisComponent.loadSpecials();
            const currentEpochTime = Math.round(new Date().getTime() / 1000);
            UserData.setItem(userDataKeys.offer_wall_visit_start, currentEpochTime);
            OfferEvents.generateOfferWallVisitID();
            window.addEventListener("beforeunload", thisComponent.beforeUnloadHandler);
        }
    }

    componentDidUpdate() {
        let { selectedHashTags, selectedDifficulty, selectedSortOption } = this.state;
        Mixpanel.trackByArgs(mixpanelEvents.playground_filter_selected, {
            difficulty_level: selectedDifficulty,
            hashtags: selectedHashTags,
            sorting_selected: selectedSortOption
        });
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", thisComponent.beforeUnloadHandler);
        if (UserData.getItem(userDataKeys.offer_wall_visit_id) !== null) {
            OfferEvents.recordOfferPageVisit();
            OfferEvents.recordOfferWallVisit();
        }
    }

    sendImpressionsEvent(apps) {
        let impressions = [];
        let appOffers;
        let offer;
        apps.forEach((app) => {
            appOffers = app.offers;
            if (appOffers.length === 1) {
                offer = appOffers[0];
                impressions.push({
                    offerID: offer.ID,
                    offerType: offer.offerType,
                    appName: app.appName,
                    category: offer.category,
                    payout: offer.coins,
                    isFree: offer.isFree,
                });
            }
        });
        if (impressions.length > 0) {
            OfferEvents.sendEvent({
                impressions: impressions,
                eventType: eventTypes.offer_impression,
                offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id)
            });
        }
    }

    /*
    sendFavouriteImpressionsEvent(offers) {
        let impressions = [];
        offers.forEach((offer) => {
            let offerID = offer.ID;
            if (!thisComponent.impressionsSent.includes(offerID)) {
                thisComponent.impressionsSent.push(offerID);
                impressions.push({
                    offerID: offerID,
                    offerType: offer.offerType,
                    appName: offer.appName,
                    category: offer.category,
                    payout: offer.coins,
                    isFree: offer.isFree,
                });
            }
        });
        if (impressions.length > 0) {
            OfferEvents.sendEvent({
                impressions: impressions,
                eventType: eventTypes.offer_impression,
                offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id)
            });
        }
    }
    */

    sendAppImpressionsEvent(offers) {
        let impressions = [];
        offers.forEach((offer) => {
            let offerID = offer.ID;
            if (!thisComponent.impressionsSent.includes(offerID)) {
                thisComponent.impressionsSent.push(offerID);
                impressions.push({
                    offerID: offerID,
                    offerType: offer.offerType,
                    appName: offer.appName,
                    category: offer.category,
                    payout: offer.coins,
                    isFree: offer.isFree,
                });
            }
        });
        if (impressions.length > 0) {
            OfferEvents.sendEvent({
                impressions: impressions,
                eventType: eventTypes.offer_impression,
                offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id)
            });
        }
    }

    toggleApp(appName, uniqueID) {
        let isVisible, newClassName;
        let toggleAppArrowMobile = document.getElementById(`toggle-${appName}`);
        let toggleAppArrowDesktop = document.getElementById(`toggle-${appName}-desktop`);
        let offerSection = document.getElementById(`app-offers-${appName}-${uniqueID}`);

        isVisible = (offerSection.className === 'app-list-offers visible open');
        newClassName = isVisible ? 'app-list-offers visible' : 'app-list-offers visible open';
        offerSection.className = newClassName;
        

        if (newClassName === 'app-list-offers visible open' ) {

            toggleAppArrowDesktop.className = 'rotate180 offers-toggle desktop';
            toggleAppArrowMobile.className = 'rotate180 offers-toggle mobile';
        }
        else {

            toggleAppArrowDesktop.className = 'offers-toggle desktop';
            toggleAppArrowMobile.className = 'offers-toggle mobile';

        }
    }

    generateApp(item) {

        const app = item.apps[0];
        const offers = app.offers;
        let numIncompleteOffers = offers?.length;
        let appLabel = app.appName.replaceAll("'", "").replaceAll("+", "").replaceAll(":", "_").replaceAll(" ", "_").replaceAll(".", "_").replace("__", "_").toLowerCase();
        let appName = app.appName.replaceAll("'", "").replaceAll("+", "").replaceAll(":", "_").replaceAll(" ", "_").replaceAll(".", "_").replace("__", "_").toLowerCase();
        let appFragment = window.location.hash.substring(1);
        let expandApp = (appFragment === appLabel);

        const appElement = (
            <div className='list-app-container'>
                <div className="list-offer-container-image">
                    <OfferDifficultyIndicator difficultyLevel={app.difficultyLevel} difficultyLevelID={app.difficultyLevelID} imageType="white" />
                    <img className="list-offer-image" src={app.imageURL} alt={appName} width="36" height="auto" />
                    <OfferThemeIndicator offer={{ offerTheme: app.appTheme, offerThemeID: app.appThemeID }} />
                </div>
                <div className="list-offer-container-titles desktop">
                    <div>
                        <div className="list-offer-app-name">{app.appName}</div>
                        <div className="list-offer-description">{Formatting.format(Content.getValue("offers_to_complete"), ['(numIncompleteOffers)'], [numIncompleteOffers])}</div>
                        <div className="list-offer-hash-tags"></div>
                    </div>
                </div>
                <div className="list-offer-container-button desktop">
                    <button className="list-offer-button desktop" onClick={() => {
                        if (document.getElementById(`app-offers-${appName}-${offers[0].uniqueID}`).className === 'app-list-offers visible') {
                            if (ApiFacade.isLoggedIn()) {
                                thisComponent.sendAppImpressionsEvent(offers);
                            }
                        }
                        thisComponent.toggleApp(appName, offers[0].uniqueID);
                    }}>
                        <p className="coins-amount">{Formatting.formatTreatCoinAmount(app.totalCoins)}</p>
                        <p>{Content.getValue("treat_coins")}</p>
                        <img src={Images.imageURL('offers-arrow-down.png')} id={`toggle-${appName}`} width="18" height="auto" alt="Toggle app" className="offers-toggle mobile" />
                        <img src={Images.imageURL('offers-arrow-down-purple.png')} id={`toggle-${appName}-desktop`} width="18" height="auto" alt="Toggle app" className="offers-toggle desktop" />
                    </button>
                </div>
            </div>
        );

        return (
            <React.Fragment key={`app-${appName}-${offers[0].position}`} >
                <div className="app-wrapper" id={`${appLabel}-section`}> 
                    {
                        (item.length===1) ?
                        (
                            <BlurProvider key={`_offer-container-${offers[0].ID}_${Date.now()}`} count={1}>
                                {
                                    (offers.length > 1) ?
                                    ( appElement ) :
                                    (null)
                                }
                                    {thisComponent.generateOffersList(appName, offers, numIncompleteOffers, expandApp, appElement)}
                            </BlurProvider>
                        ) :
                            (item.length>1) ?
                            (
                                <BlurProvider key={`_offer-container-${offers[0].ID}_${Date.now()}`} count={item.length}>
                                    {
                                        item.apps.map(({ offers }) => (
                                            thisComponent.generateOffersList(appName, offers, offers.length, expandApp, appElement) 
                                        ))
                                    }
                                </BlurProvider>
                            ) :
                                (
                                    <React.Fragment key={`_offer-container-${offers[0].ID}_${Date.now()}`}>
                                        {thisComponent.generateOffersList(appName, offers, numIncompleteOffers, expandApp, appElement)}
                                    </React.Fragment>
                                )
                    }
                </div>
            </React.Fragment>
        );
    }

    generateOffersList(appName, offers, numIncompleteOffers, expandApp, appElement) {        
        const { selectedHashTags } = thisComponent.state;

        return (
            <div key={`_offer-parent-${appName}-${Math.random()}_${Date.now()}`}>
                {
                    (numIncompleteOffers > 1) ?
                    ( appElement ) :
                    (null)
                }
                <div id={`app-offers-${appName}-${offers[0].uniqueID}`} className={(numIncompleteOffers > 1 && !expandApp) ? 'app-list-offers visible' : 'app-list-offers visible open'}>
                    {offers.map(offer => (
                        (offer.isTodaysBest) ?
                        (<FeaturedOffer offer={offer} mode={featuredOfferModes.todays_best} selectedHashTags={selectedHashTags} key={`_offer-todays-best-${offer.ID}_${Date.now()}`} />) :
                            (!offer.isSystem) ?
                                <IncompleteOffer offer={offer} selectedHashTags={selectedHashTags} numIncompleteOffers={numIncompleteOffers} mode={incompleteOfferModes.main_offers_list} key={`_offer-main-${offer.ID}_${Date.now()}`} /> :
                                <FeaturedOffer offer={offer} mode={featuredOfferModes.system} selectedHashTags={selectedHashTags} key={`_offer-system-${offer.ID}_${Date.now()}`} /> 

                    ))}
                </div>
            </div>
        );
    }

    /*
    generateFavouriteOffers(favouriteOffers, selectedHashTags) {
        const data = Offers.getBlurredOfferData(favouriteOffers);

        if (favouriteOffers.length > 0) {
            thisComponent.sendFavouriteImpressionsEvent(favouriteOffers);

            return (
                <div className="favourite-offers-container-wrapper desktop">
                    <div className="favourite-offers-container desktop offers-list" id="favourite-offers-container">
                        <div className="favourite-offers-wrapper desktop" id="favourite-offers-wrapper">
                            <h2 className="favourite-offers-section-title desktop">{Content.getValue("title_your_popular_offers")}</h2>
                            <div className="favourite-offers-section-description desktop">{Content.getValue("your_popular_offers_description")}</div>
                            <div className="favourite-offers-list desktop">
                                {
                                    data.map(item => (item.length===1) ?
                                        (
                                            <BlurProvider key={`_offer-container-${item.offers[0].ID}_${Date.now()}`} count={1}>
                                                <IncompleteOffer offer={item.offers[0]} selectedHashTags={selectedHashTags} mode={incompleteOfferModes.favourite_offers_list} />
                                            </BlurProvider>
                                        ) :
                                            (item.length>1) ?
                                            (
                                                <BlurProvider key={`_offer-container-${item.offers[0].ID}_${Date.now()}`} count={item.length}>
                                                    {item.offers.map(offer => (
                                                        <IncompleteOffer key={`_offer-favourite-${offer.ID}_${Date.now()}`} offer={offer} selectedHashTags={selectedHashTags} mode={incompleteOfferModes.favourite_offers_list} />
                                                    ))}
                                                </BlurProvider>
                                            ) :
                                                (
                                                    <IncompleteOffer key={`_offer-container-${item.offers[0].ID}_${Date.now()}`} offer={item.offers[0]} selectedHashTags={selectedHashTags} mode={incompleteOfferModes.favourite_offers_list} />
                                                )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
        return null;
    }
    */


    toggleHashTag(hashTag) {
        let { selectedHashTags, selectedDifficulty, selectedSortOption } = this.state;
        let hashTagContainer = document.getElementById(`hash-tag-${hashTag}`);
        let oldClassName = hashTagContainer.className;
        let newClassName;
        if (oldClassName === 'hash-tag-container') {
            newClassName = 'hash-tag-container marked';
            selectedHashTags.push(hashTag);
        }
        else {
            newClassName = 'hash-tag-container';
            selectedHashTags = selectedHashTags.filter(e => e !== hashTag);
        }
        hashTagContainer.className = newClassName;

        let filteredOffers = Offers.filterAppOffers(JSON.parse(JSON.stringify(allOffers)), selectedHashTags, selectedDifficulty, selectedSortOption);
        // let filteredSpecialOffers = Offers.filterAppOffers(JSON.parse(JSON.stringify(allSpecialOffers)), selectedHashTags, selectedDifficulty, selectedSortOption);
        // let filteredFavouriteOffers = Offers.filterOffers(JSON.parse(JSON.stringify(allFavouriteOffers)), selectedHashTags, selectedDifficulty);

        thisComponent.setState({
            ...thisComponent.state,
            selectedHashTags: selectedHashTags,
            offers: filteredOffers,
            // specialOffers: filteredSpecialOffers,
            isLoaded: true,
            // favouriteOffers: filteredFavouriteOffers,
            // isFavouriteLoaded: true
        });
    }

    // isHashtagVisible(hashTag, allOffers, allSpecialOffers, favouriteOffers) {
    //     const isVisibleForSome = allOffers?.some(item => item.hashTags.includes(hashTag));
    //     const isVisibleForSpecial = allSpecialOffers?.some(item => item.hashTags.includes(hashTag));
    //     const isVisibleForFav = favouriteOffers?.some(item => item.hashTags.includes(hashTag));

    //     return isVisibleForSome || isVisibleForSpecial || isVisibleForFav;
    // }

    setDifficultyLevel(difficulty, idToMark) {
        let { selectedSortOption } = this.state;

        document.getElementById(markedDifficultyId).className = document.getElementById(markedDifficultyId).className.replace(" marked", "");
        document.getElementById(idToMark).className += " marked";
        markedDifficultyId = idToMark;

        let filteredOffers = Offers.filterAppOffers(JSON.parse(JSON.stringify(allOffers)), [], difficulty, selectedSortOption);
        // let filteredSpecialOffers = Offers.filterAppOffers(JSON.parse(JSON.stringify(allSpecialOffers)), [], difficulty, selectedSortOption);
        // let filteredFavouriteOffers = Offers.filterOffers(JSON.parse(JSON.stringify(allFavouriteOffers)), [], difficulty);

        thisComponent.setState({
            ...thisComponent.state,
            selectedDifficulty: difficulty,
            selectedHashTags: [],
            offers: filteredOffers,
            // specialOffers: filteredSpecialOffers,
            // favouriteOffers: filteredFavouriteOffers,
            isLoaded: true,
            // isFavouriteLoaded: true
        });
    }

    toggleSortOption(sortOption) {
        let { selectedHashTags, selectedDifficulty, selectedSortOption } = this.state;

        let newSortingOn;
        let newSelectedSortOption;

        if (sortOption === selectedSortOption) {
            newSortingOn = false;
            newSelectedSortOption = null;
        }
        else {
            newSortingOn = true;
            newSelectedSortOption = sortOption;
        }

        let filteredOffers = Offers.filterAppOffers(JSON.parse(JSON.stringify(allOffers)), selectedHashTags, selectedDifficulty, newSelectedSortOption);

        setTimeout(function () {
            thisComponent.setState({
                ...thisComponent.state,
                isOpenSortOptions: false,
                offers: filteredOffers,
                selectedSortOption: newSelectedSortOption,
                isSortingOn: newSortingOn
            });
        }, 25);
    }

    render() {
        let {
            error,
            isLoaded,
            // isSpecialLoaded,
            offers,
            //favouriteError,
            //isFavouriteLoaded,
            //favouriteOffers,
            displayAll,
            isOpenSortOptions,
            isSortingOn,
            sortOptions,
            selectedSortOption,
            selectedHashTags,
            todaysBest,
            // specialOffers,
        } = this.state;

        offers?.forEach(item => {
            item.isAppInVisible = item.offers?.some(each => !each.isAvailable);
        })

        const data = Offers.getBlurredAppData(offers);
        // const allSpecialOffers = specialOffers?.reduce((res, each) => res.concat(each.offers), []) || [];
        // const availableHashtags = hashTags?.filter(each => thisComponent.isHashtagVisible(each, allOffers, allSpecialOffers, favouriteOffers));

        if (error) {
            return <>
                <div className="offers-list-difficulty">
                    <div className="offers-section-difficulty all-offers marked" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.all, 'all-offers')} id="all-offers">{Content.getValue("offer_difficulties")["0"]}</div>
                    <div className="offers-section-difficulty easy-offers" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.easy, 'easy-offers')} id="easy-offers">{Content.getValue("offer_difficulties")["1"]}</div>
                    <div className="offers-section-difficulty medium-offers" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.medium, 'medium-offers')} id="medium-offers">{Content.getValue("offer_difficulties")["2"]}</div>
                    <div className="offers-section-difficulty hard-offers" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.hard, 'hard-offers')} id="hard-offers">{Content.getValue("offer_difficulties")["3"]}</div>
                </div>
                <div className="error-message">{Backend.loadBackendMessages().errorMessage}</div>
            </>;
        } else if (!isLoaded) {
            return <>
                <div className="offers-list-difficulty">
                    <div className="offers-section-difficulty all-offers marked" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.all, 'all-offers')} id="all-offers">{Content.getValue("offer_difficulties")["0"]}</div>
                    <div className="offers-section-difficulty easy-offers" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.easy, 'easy-offers')} id="easy-offers">{Content.getValue("offer_difficulties")["1"]}</div>
                    <div className="offers-section-difficulty medium-offers" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.medium, 'medium-offers')} id="medium-offers">{Content.getValue("offer_difficulties")["2"]}</div>
                    <div className="offers-section-difficulty hard-offers" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.hard, 'hard-offers')} id="hard-offers">{Content.getValue("offer_difficulties")["3"]}</div>
                </div>
                <div className="offers-spinner-container"><Spinner color="#EE9AD8" className="offers-list-spinner" /></div>
            </>;
        } else {
           // if (isSpecialLoaded) {
            thisComponent.displayRecommendedOffer();
            // }
            return (
                <>
                    <div className="offers-list-difficulty">
                        <div className="offers-section-difficulty all-offers marked" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.all, 'all-offers')} id="all-offers">{Content.getValue("offer_difficulties")["0"]}</div>
                        <div className="offers-section-difficulty easy-offers" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.easy, 'easy-offers')} id="easy-offers">{Content.getValue("offer_difficulties")["1"]}</div>
                        <div className="offers-section-difficulty medium-offers" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.medium, 'medium-offers')} id="medium-offers">{Content.getValue("offer_difficulties")["2"]}</div>
                        <div className="offers-section-difficulty hard-offers" onClick={() => thisComponent.setDifficultyLevel(offerDifficulties.hard, 'hard-offers')} id="hard-offers">{Content.getValue("offer_difficulties")["3"]}</div>
                    </div>
                    <div id="offers-list-hash-tags">
                    {/* <ScrollContainer className="offers-list-hash-tags" >
                        {availableHashtags.map(
                            function (hashTag) {
                                return <span
                                    className={selectedHashTags.includes(hashTag) ? 'hash-tag-container marked' : 'hash-tag-container'}
                                    id={`hash-tag-${hashTag}`}
                                    onClick={() => thisComponent.toggleHashTag(hashTag)}
                                    key={`hash-tag-${hashTag}`}>
                                    {hashTag}
                                </span>;
                            }
                            )}
                    </ScrollContainer> */}
                    </div>

                    {/*   
                    {
                        (favouriteError) ?
                            (
                                <div className="error-message">{Backend.loadBackendMessages().errorMessage}</div>
                            ) :
                            (
                                (isFavouriteLoaded) ? (
                                    thisComponent.generateFavouriteOffers(favouriteOffers, selectedHashTags)
                                ) : (null)
                            )
                    }
                    */}

                    {/*
                    {
                        ApiFacade.isLoggedIn() && allSpecialOffers.length > 0 ?
                            (
                                <div className='special-offers'>
                                    <div className='special-offers-container desktop'>
                                        <div className="special-offers-child desktop margin-bottom-0 flex gap-20">
                                            <div className="background" />
                                            <div className='special-div desktop mobile-margin-right-10'>
                                                <div className='special-header desktop'>
                                                    <h2 className='margin-0 desktop special-offer-modal-title'>{ Content.getValue("special_offers") }</h2>
                                                    <h2 className='mobile-margin-top-0 desktop special-offer-modal-title'>{ Content.getValue("@_treatcoin") }</h2>
                                                </div>
                                                <SeeOffersButton 
                                                    data={{
                                                        offers: allSpecialOffers,
                                                        selectedHashTags
                                                    }} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            (null)
                    }
                    */}

                    {
                        (offers.length > 0) ? (
                            <>
                                <div className="desktop list-offers-div-wrapper">
                                    <div className="desktop list-offers-div  filled" id="desktop list-offers-div ">
                                        <div className="sort-by-offers-button-container">
                                            <button className={isSortingOn ? 'sort-by-offers-button highlighted' : 'sort-by-offers-button'} onClick={() => {
                                                thisComponent.setState({ ...thisComponent.state, isOpenSortOptions: !thisComponent.state.isOpenSortOptions });
                                            }}>
                                                {isSortingOn ? Formatting.format(Content.getValue("sorted_by"), ["(sortOption)"], [Content.getValue("offer_sort_groups")[selectedSortOption]]) : Content.getValue("sort_by")}
                                                <img src={Images.imageURL(isSortingOn ? 'sort-offers-highlighted.png' : 'sort-offers.png')} alt="Sort offers" className="sort-offers-icon" width="14" height="auto" />
                                            </button>
                                            <div className={`sort-by-offers-lists ${isOpenSortOptions ? 'show' : ''}`}>
                                                {sortOptions.map(
                                                    function (sortOption) {
                                                        return <span className={`sort-by-offers-lists-item ${selectedSortOption === sortOption ? 'active' : ''}`} onClick={() => thisComponent.toggleSortOption(sortOption)} key={`sort-option-${sortOption}`}>{Content.getValue("offer_sort_groups")[sortOption]}</span>;
                                                    }
                                                )}
                                            </div>
                                        </div>
                                        
                                        {(todaysBest) ?
                                            (todaysBest.isAvailable) ?
                                                (<FeaturedOffer offer={todaysBest} mode={featuredOfferModes.todays_best} selectedHashTags={selectedHashTags} key={`_offer-todays-best-${todaysBest.ID}_${Date.now()}`} />) :
                                                (<BlurProvider count={1}>
                                                    <FeaturedOffer offer={todaysBest} mode={featuredOfferModes.todays_best} selectedHashTags={selectedHashTags} key={`_offer-todays-best-${todaysBest.ID}_${Date.now()}`} /> 
                                                </BlurProvider>) :
                                            (null)
                                        }

                                        <div>
                                            {data.slice(0, offersToShowInitially).map(
                                                function (item) {
                                                    return thisComponent.generateApp(item);
                                                }
                                            )}
                                            {
                                                (displayAll) ?
                                                    (data.slice(offersToShowInitially).map(
                                                        function (item) {
                                                            return thisComponent.generateApp(item);
                                                        }
                                                    )) :
                                                    (null)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="no-available-offers">{Content.getValue("no_offers_available")}</div>
                        )
                    }
                    {
                        (!displayAll && offers.length > offersToShowInitially) ?
                            (<div className="see-all-offers-button-container desktop">
                                <button className="see-all-offers-button incomplete-offers-list" onClick={() => {
                                    if (ApiFacade.isLoggedIn()) {
                                        thisComponent.sendImpressionsEvent(offers.slice(offersToShowInitially));
                                    }
                                    thisComponent.setState({ ...thisComponent.state, displayAll: true });
                                }}>{Content.getValue("see_all_offers")}</button>
                            </div>) : (null)
                    }
                    {
                        (offers.length > 0) ? (
                            <ScrollToTop containerClass="scroll-to-top-container offers-list desktop" />
                        ) :
                        (null)
                    }
                </>
            );
        }
    }
}

export default IncompleteOffersList;