import React, { useState } from 'react';
import Content from 'utils/Content';
import Images from 'utils/Images';
import ChooseSocialsButton from 'components/buttons/Choose_Socials_Button';
import LinkButton from 'components/buttons/Link_Button';
import UserData from 'User_Data.js';
import Sharing from 'utils/Sharing';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

const websiteReferralLink = UserData.getItem(userDataKeys.website_referral_link);
const telegramReferralLink = UserData.getItem(userDataKeys.telegram_referral_link);

const StatsShowHeading = ({ setShowStats, showStats }) => (
    <div
        style={{ display: showStats ? 'none' : 'flex' }}
        className={`earn-team-popup-links-second `}>

        <button className='hidden-button' onClick={() => { setShowStats(true); }}>
            <img src={Images.imageURL('bottom-menu/back-arrow-btn.png')}
                width='32' height='72'
                alt="BackArrow" className='link-button-image-back' />
        </button>
        <div className="earn-team-popup-links-second-desc">
            <div className='earn-team-popup-links-second-desc-reward'>
                <p className='earn-team-popup-gen-green'>{Content.getValue('rewards')}</p>
                <img src={Images.imageURL('bottom-menu/treat-coin-27x4.png')}
                    height='32' width='32'
                    alt="TreatCoin" className='treat-coin-27' />
            </div>
            <p>{Content.getValue('from_your_team')}</p>
        </div>
    </div>
);

function EarnTeamPopup({setOpenTermsPopup}) {
    const [showStats, setShowStats] = useState(false);
    const [link, setLink] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    const setInfo = (num) => {
        const terms = Number(UserData.getItem(userDataKeys.terms));
        if (!terms) {
            setTimeout(() => {
                setOpenTermsPopup(true);
            }, 100);
        } else { 
            setLink(num === 1 ? UserData.getItem(userDataKeys.website_referral_link) : UserData.getItem(userDataKeys.telegram_referral_link));
        }
    };

    const copyLink = async () => {
            await Sharing.copyToClipboard(link, Content.getValue("copy_to_clipboard_fallback_title"), Content.getValue("copy_to_clipboard_fallback_text"));
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
                setLink(null);
            }, 1000);
    };

    const shareLink = async () => {
            await Sharing.shareLink(
                {
                    title: Content.getValue("join_treatcoin"),
                    text: Content.getValue("share_link_cta_message"),
                    url: link,
                   fallbackTitle: Content.getValue("share_link_fallback_title"),
                   fallbackMessage: Content.getValue("share_link_fallback_message"),
                   detailsImageURL: null
                }
            );
        setLink(null);
    };

    return (<>
        <div className='earn-team-popup'>
            <h4 className="earn-team-popup-name">{Content.getValue('earn_building_team')}</h4>
            <div className="earn-team-popup-container">
                <div className={`${showStats ? 'hide-team-stats' : 'show-team-stats'}`}>
                    <div className="earn-team-popup-links">
                        <div className="earn-team-popup-links-first">
                            <p className="earn-team-popup-links-first-desc">
                                <span className='earn-team-popup-gen-green'>{Content.getValue('invite_uppercase')}</span>
                                <span> </span>
                                {Content.getValue('your_team_through')}
                            </p>

                            <div className={`earn-team-popup-links-first-buttons ${link ? 'display-none-mobile' : ''}`}>
                                <ChooseSocialsButton
                                    imageRoute={'bottom-menu/web.png'}
                                    text={Content.getValue('user_stats_web')}
                                    chosen={link === websiteReferralLink}
                                    onClick={() => setInfo(1)}
                                />
                                <ChooseSocialsButton
                                    imageRoute={'bottom-menu/telegram.png'}
                                    text={Content.getValue('user_stats_telegram')}
                                    chosen={link === telegramReferralLink}
                                    onClick={() => setInfo(2)}
                                />
                            </div>
                            {link &&
                                <button className="hidden-button earn-team-popup-links-back-btn" onClick={() => { setLink(null); }}>
                                    <ChevronLeftIcon fontSize='large' />
                                    <p className="link-button-text">{Content.getValue('back')}</p>
                                </button>
                            }
                            <div className={`earn-team-popup-links-first-links ${!link ? 'display-none-tablet' : ''}`}>

                                <LinkButton disabled={link === null} onClick={shareLink} imageRoute={'bottom-menu/share-link.png'} text={Content.getValue('user_stats_share_link')} />
                                <p>{Content.getValue('or_label')}</p>
                                <LinkButton disabled={link === null} onClick={copyLink} imageRoute={'bottom-menu/copy-link.png'} text={isCopied ? Content.getValue("copied_to_clipboard") : Content.getValue('user_stats_copy_link')} />
                            </div>

                        </div>
                        <StatsShowHeading setShowStats={setShowStats} />
                    </div>
                </div>
                <div className={`${showStats ? 'show-team-stats' : 'hide-team-stats'}`}>
                    <StatsShowHeading showStats={showStats} />
                    <div className="earn-team-popup-main">
                        <button
                            onClick={() => { setShowStats(false); }}
                            className='hidden-button earn-team-popup-cancel-btn'>
                            <img className='earn-team-popup-cancel-btn-img' src={Images.imageURL('bottom-menu/cancel-btn.png')} alt="TreatCoin" />
                        </button>
                        <div className="earn-team-popup-gen">
                            <p className='earn-team-popup-gen-number'>{Content.getValue('1st_gen_earns')}</p>
                            <div className="earn-team-popup-gen-stats">
                                <div className='flex justify-center gap-5-i '>
                                    <img src={Images.imageURL('bottom-menu/treat-coin.png')} alt="TreatCoin" />
                                    <p className='earn-team-popup-gen-white-desc'>
                                        150
                                    </p>
                                </div>
                                <p className='earn-team-popup-gen-grey'>{Content.getValue('per_sign_up')}</p>
                                <div className="earn-team-popup-gen-line"></div>
                                <div className='flex-desktop-center'>
                                    <div className='flex justify-center gap-5-i '>
                                        <img src={Images.imageURL('bottom-menu/treat-coin.png')} alt="TreatCoin" />
                                        <p className='earn-team-popup-gen-green'>39%</p>
                                    </div>
                                    <p className=' earn-team-popup-gen-white-desc desktop-white-18-semi'>{Content.getValue('on_top_of')}</p>
                                </div>
                                <p className='earn-team-popup-gen-grey'>{Content.getValue('offers_they_complete')}</p>
                            </div>
                        </div>
                        <div className="earn-team-popup-gen">
                            <p className='earn-team-popup-gen-number'>{Content.getValue('2nd_gen_earns')}</p>
                            <div className="earn-team-popup-gen-stats">
                                <div className='flex justify-center gap-5-i '>
                                    <img src={Images.imageURL('bottom-menu/treat-coin.png')} alt="TreatCoin" />
                                    <p className='earn-team-popup-gen-white-desc'>
                                        50
                                    </p>
                                </div>
                                <p className='earn-team-popup-gen-grey'>{Content.getValue('per_sign_up')}</p>
                                <div className="earn-team-popup-gen-line"></div>
                                <div className='flex-desktop-center'>
                                    <div className='flex justify-center gap-5-i '>
                                        <img src={Images.imageURL('bottom-menu/treat-coin.png')} alt="TreatCoin" />
                                        <p className='earn-team-popup-gen-green'>14%</p>
                                    </div>
                                    <p className='earn-team-popup-gen-white-desc desktop-white-18-semi'>{Content.getValue('on_top_of')}</p>
                                </div>
                                <p className='earn-team-popup-gen-grey '>{Content.getValue('offers_they_complete')}</p>
                            </div>
                        </div>
                        <div className="earn-team-popup-gen">
                            <p className='earn-team-popup-gen-number'>{Content.getValue('3rd_gen_earns')}</p>
                            <div className="earn-team-popup-gen-stats">
                                <div className='flex justify-center gap-5-i '>
                                    <img src={Images.imageURL('bottom-menu/treat-coin.png')} alt="TreatCoin" />
                                    <p className='earn-team-popup-gen-white-desc'>
                                        20
                                    </p>
                                </div>
                                <p className='earn-team-popup-gen-grey'>{Content.getValue('per_sign_up')}</p>
                                <div className="earn-team-popup-gen-line"></div>
                                <div className='flex-desktop-center'>
                                    <div className='flex justify-center gap-5-i '>
                                        <img src={Images.imageURL('bottom-menu/treat-coin.png')} alt="TreatCoin" />
                                        <p className='earn-team-popup-gen-green'>7%</p>
                                    </div>
                                    <p className='earn-team-popup-gen-white-desc desktop-white-18-semi '>{Content.getValue('on_top_of')}</p>
                                </div>
                                <p className=' earn-team-popup-gen-grey'>{Content.getValue('offers_they_complete')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>
    );
}

export default EarnTeamPopup;