import ApiFacade from 'ApiFacade.js';
import { SpinnerContextProvider } from 'context/Global_Spinner';
import Main from 'Main.js';
import { useEffect, useContext } from 'react';
import UserData from 'User_Data.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import jwt from 'jwt-decode';
import { MenuContextProvider } from 'context/Menu_Context';
import { OfferPopupContextProvider } from 'context/Offer_Popup_Context.js';
import Moengage from 'utils/Moengage';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from 'context/Global_Context';


const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;
const businessLogic = configuration.business_logic;

const tokenRefreshInterval = businessLogic.token_refresh_interval;


function App() {
  const location = useLocation();

  const { siteLayout, setSiteLayout } = useContext(GlobalContext);

  useEffect(() => {
    let j = UserData.getItem(userDataKeys.jwt_token);
    let tmpUid = j && jwt(j) && jwt(j).uniqueUserID;

    if (tmpUid) {
      Moengage.init(tmpUid);
    }

  }, []);

  useEffect(() => {
    let userIdentifiedInMixpanel = UserData.getItem(userDataKeys.mixpanel_user_identified);
    let jwtToken = UserData.getItem(userDataKeys.jwt_token);
    let jwtTokenExists = jwtToken && jwt(jwtToken) && jwt(jwtToken).uniqueUserID;

    if (jwtToken && jwtTokenExists) {

      const uniqueUserID = jwt(jwtToken).uniqueUserID;
      if (userIdentifiedInMixpanel !== '1') {
        let [shouldSetAlias, oldDeviceId] = checkShouldSetAlias();
        Mixpanel.identify(uniqueUserID);
        if (shouldSetAlias) {
          Mixpanel.alias(uniqueUserID, oldDeviceId);
        }
        UserData.setItem(userDataKeys.mixpanel_user_identified, '1');
      }
    }
  }, [location])


    const checkShouldSetAlias = () =>  {
      let oldDeviceId = UserData.getItem(userDataKeys.device_id);
      let currentDeviceId = Mixpanel.getDistinctId();
      UserData.removeItem(userDataKeys.device_id);
      UserData.removeItem(userDataKeys.device_id_sent);
      let shouldSetAlias = 
       (oldDeviceId && 
        currentDeviceId && 
        oldDeviceId !== currentDeviceId);
      return [shouldSetAlias, oldDeviceId];
    }

    const setupInterval =  (callback, interval) =>  {
        let now = Date.now();
        let refreshCallTime = UserData.getItem(userDataKeys.refresh_call_time);
        let executeCallback = function () {
          UserData.setItem(userDataKeys.refresh_call_time, Date.now());
          callback();
        }
        if (refreshCallTime) { 
          let time = parseInt(refreshCallTime);
          let delta = now - time;
          if (delta > interval) { 
            executeCallback();
            setInterval(executeCallback, interval);
          } else { 
            setTimeout(function () {
              setInterval(executeCallback, interval);
              executeCallback();
            }, interval - delta);
          }
        } else {
          UserData.setItem(userDataKeys.refresh_call_time, now);
          setInterval(executeCallback, interval);
        }
    }


  if (!window.tokenRefreshSet && ApiFacade.isLoggedIn()) {
        window.tokenRefreshSet = true;
        updateLayout();
        setupInterval(function () {
            // TO DO : UNCOMMENT!!!!
            let now = Date.now();
            UserData.setItem(userDataKeys.refresh_call_time, now);
            //Backend.refreshToken();
            ApiFacade.refreshToken().then(() => {
              updateLayout();
            }); // Async function, but the result not that interests us
          }, tokenRefreshInterval);
          
    }

    function updateLayout(){
      const jwt_token = UserData.getItem(userDataKeys.jwt_token);
      let isGold = jwt_token && jwt(jwt_token) && jwt(jwt_token).isGold;
      if(isGold == 1 && siteLayout != "gold"){
        setSiteLayout("gold")
      }
    }

    return (
      <div id='rootChild'>
        <SpinnerContextProvider>
          <MenuContextProvider>
            <OfferPopupContextProvider>
              <Main />
            </OfferPopupContextProvider>
          </MenuContextProvider>
        </SpinnerContextProvider>
      </div>
    );
}

export default App;