import React from 'react';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Alerts from 'utils/Alerts.js';
import Content from 'utils/Content.js';

let thisComponent;


class EmailSubscriptionSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isRestricted: false,
            emailSubscriptionSettings: null
        };
        thisComponent = this;
    }

    componentDidMount() {
        const req = ApiFacade.emailSubscriptionSettings();
        req.then(
            function (res) {
                if (res.ok){
                    let result = res.response;
                    thisComponent.setState({
                        isLoaded: true,
                        subscriptionSettings: result.subscriptionSettings
                    });
                } else if (res.unauthorized){
                    thisComponent.setState({
                        isLoaded: true,
                        isRestricted: true
                    });
                } else {
                    thisComponent.setState({
                        isLoaded: true,
                        error: res.error
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    render() {
        const { error, isLoaded, isRestricted, subscriptionSettings } = this.state;
        if (error) {
            return <div className="error-message">{Backend.loadBackendMessages().errorMessage}</div>;
        } else if (isRestricted) {
            return (null);
        } else if (!isLoaded) {
            return <div className="loading-message">{Backend.loadBackendMessages().loadingMessage}</div>;
        } else {
            return (
                <div className='notification-settings-email-subscription'>
                <div className="subscription-settings-section">
                    <div className="subscription-settings-options-section email-setting">
                        <div className="settings">
                            <h2 className="account-settings-subtitle">{Content.getValue("subscription_settings_email")}</h2>
                            <label className="checkbox-container subscription-settings-container" htmlFor="receiveNotificationsEmail">
                                <span className="notification-setting-label">{Content.getValue("receive_notifications_reminders")}</span>
                                <input type="checkbox" id="receiveNotificationsEmail" name="receiveNotificationsEmail" className="subscription-settings-checkbox" value="receiveNotificationsEmail" defaultChecked={subscriptionSettings.receiveNotificationsEmail} />
                                <span className="checkmark-checkbox"></span>
                                </label>   
                        </div>
                    </div>
                    <button className="account-settings-button" onClick={this.updateEmailSubscriptionSettings}>{Content.getValue("update_subscription_settings_button_text")}</button>
                    </div>
                </div>
            );
        }
    }

    updateEmailSubscriptionSettings() {
        let receiveNotificationsEmailInput = document.getElementById('receiveNotificationsEmail');
        const req = ApiFacade.updateEmailSubscriptionSettings(receiveNotificationsEmailInput.checked);

        req.then(function(res){
                let result = res.response;
                let message = result.message;
                if (res.ok) {
                    Alerts.showSuccessAlert(Content.getValue("email_subscription_settings_updated"));
                }
                else {
                    Alerts.showErrorAlert(message);
                }
            });
    }
}

export default EmailSubscriptionSettings;