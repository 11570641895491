import React, { useState, useEffect, memo } from 'react';
import ApiFacade from 'ApiFacade.js';
import UserData from 'User_Data.js';
import Platform from 'utils/Platform.js';
import X2OfferHomepage from 'components/offers/X2_Offer_Homepage.js';
import EarnTeamPopup from 'components/offers/Earn_Team_Popup';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const incompleteOfferModes = configuration.incomplete_offer_modes;
const featuredOfferTypes = configuration.featured_offer_types;

function X2OfferLoaderHomepage({ setOpenTermsPopup }) {
    const [state, setState] = useState({
        x2Offer: null,
        error: null,
        isLoaded: false,
        isRestricted: false,
    });
    const [isFirstTime, setIsFirstTime] = useState(false);

    useEffect(() => {
        if (ApiFacade.isLoggedIn()) {
            const platform = Platform.getPlatformName();
            const req = ApiFacade.x2Offers(platform);
            req.then(function (res) {
                if (res.ok) {
                    let result = res.response;
                    UserData.setItem(userDataKeys.offer_completion_disclaimer_displayed, result.coinsDisclaimerDisplayed);
                    setState({
                        isLoaded: true,
                        x2Offer: result.x2Offer
                    });
                } else if (res.unauthorized) {
                    setState({
                        isLoaded: true,
                        isRestricted: true
                    });
                } else {
                    setState({
                        isLoaded: true,
                        error: res.error
                    });
                }
            },
                (error) => {
                    setState({
                        isLoaded: true,
                        error
                    });
                }
            )
                .catch(error => setState({ isLoaded: true, error }));
        }
    }, []);

    const { x2Offer } = state;
    const isX2Offer = x2Offer;
    const type = isX2Offer ? featuredOfferTypes.x2_offer : null;
    const offers = isX2Offer && x2Offer;

    if (state.isLoaded) {
        if (offers) {
            return (
                <X2OfferHomepage
                    key={`_offer-hot-offer-_${Date.now()}`}
                    offers={offers}
                    type={type}
                    mode={incompleteOfferModes.unavailable_offer}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            );
        }
        else {
            return (
                <>
                    <EarnTeamPopup setOpenTermsPopup={setOpenTermsPopup} />
                </>
            );
        }
    }
}

export default memo(X2OfferLoaderHomepage);