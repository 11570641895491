import React from 'react';
import Images from 'utils/Images.js';


class ScrollToTop extends React.Component {

    constructor(props) {
        super(props);
        this.containerClass = props.containerClass;
    }

    render() {
       return (
           <div className={this.containerClass}>
           <img className="scroll-to-top-button" id="scroll-to-top-button" width="44" height="44" alt="Scroll to top" src={Images.imageURL('scroll-to-top.png')} onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}/>
       </div>
       )
    }

}

export default ScrollToTop;