import React from 'react';
import Images from 'utils/Images';

function ChooseSocialsButton({ onClick, imageRoute, text, chosen }) {
    return (
        <button onClick={() => onClick()} className="hidden-button choose-socials-button">
            <svg className='choose-socials-button-circle' height="44" width="44">
                <circle cx="21" cy="21" r="19" stroke={chosen ? '#3CF2A0' : "#7D7E9D"} strokeWidth="1" fill="transparent" />
            </svg>
            <img src={Images.imageURL(imageRoute)} alt="Burger" className='choose-socials-button-image' />
            <p className={`choose-socials-button-text  ${chosen ? 'earn-team-popup-gen-green' : ''}`}>{text}</p>
        </button>
    );
}

export default ChooseSocialsButton;