import AccountAccessPageHeader from 'components/sections/Account_Access_Page_Header.js';
import * as queryString from 'query-string';
import UserData from 'User_Data.js';
import Formatting from 'utils/Formatting.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { useEffect, useState } from 'react';
import Join from 'components/widgets/Join/Join.js';

const globalConf = require('global_conf.json');

const captchaConnection = globalConf.captcha_connection;

const captchaSiteKey = captchaConnection.site_key;

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;
const urls = configuration.urls;
const userDataKeys = configuration.user_data_keys;

const googleCaptchaURL = urls.google_captcha_url;

const EmailSignup = () => {
    const [regEmail, setRegEmail] = useState();
    const existingScript = document.getElementById('google-captcha');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = Formatting.format(googleCaptchaURL, ["(captchaSiteKey)"], [captchaSiteKey]);
        script.id = 'google-captcha';
        document.body.appendChild(script);
    }

    useEffect(() => {
        UserData.removeItem(userDataKeys.email_register)
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.email_signup });
    }, []);

    const urlParams = queryString.parse(window.location.search);

    const confirmationCode = urlParams.confirmationCode;

    return (
        <>
            <div id="main" className="light-mode">
                <div id="content">
                    <AccountAccessPageHeader />
                    <section className="light-mode">
                        <div>
                            <div className="page-container light-mode">
                                <Join confirmationCode={confirmationCode} variant={'signup-page'} setRegEmail={setRegEmail} regEmail={regEmail} />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default EmailSignup;