import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Content from 'utils/Content.js';


const AddPaymentBirthDatePicker = () => {
  const [startDate, setStartDate] = useState(null);
  const currentDate = new Date();
  const maxAcceptanceDate = currentDate.setFullYear(currentDate.getFullYear() - 18);
    return (
      <DatePicker
        id='paxum-birth-date'
        size='sm'
        selected={startDate}
        className='swal-payment-methods-input'
        placeholderText={Content.getValue("birth_placeholder")}
        dateFormat='yyyy-MM-dd'
        onChange={(date) => setStartDate(date)}
        maxDate={maxAcceptanceDate}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
    );
};

export default AddPaymentBirthDatePicker;